import React, { useState, useEffect } from "react";
import Adminpdf from "./adminPdf";
import Modal from "react-modal";
import ValueFormat from "../../util/ValueFormat";
import RideDetailPdf from "./rideDetailsPdf";
import { useLocation } from "react-router";
import UserReactToPdf from "./userReactToPdf";
import {
  UdOverAllSummaryPdf,
  udOverAllSummaryPdf,
} from "../ViewPdf/User/overAllSummaryPdf";
import {
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
} from "../../constant/StorageConstant";
// import UBIDashboardPDF from "./UBIDashboardPDF";
import UtilDateTime from "../../util/UtilDateTime";
import RideDetailPdfNew from "../ViewPdf/Ridedetails/rideDetailsPdfNew";
import AdOverallSummaryPdf from "../ViewPdf/Admin/overallSummaryPdf";
import ActivityUsageSummaryPdf from "../ViewPdf/Admin/activityUsageSummaryPdf";
import AdRiskSummaryPdf from "../ViewPdf/Admin/riskSummaryPdf";
import AdCategorySummaryPdf from "../ViewPdf/Admin/categorySummaryPdf";
import CrashReportPdf from "../ViewPdf/CrashReportPDF/CrashReportPdf";
import RiskAlertSummaryPdf from "../ViewPdf/User/riskAlertSummaryPdf";
import CategorySummaryPdf from "../ViewPdf/User/categorySummaryPdf";
import AdOverallSummaryyPdf from "../ViewPdf/Admin/overallSummaryyPdf";
import ColouredCategorySummaryPdf from "../ViewPdf/Admin/colouredCategorySummaryPdf";
import ColouredRiskSummaryPdf from "../ViewPdf/Admin/ColouredRiskSummaryPdf";
import ColouredActivityUsageSummaryPdf from "../ViewPdf/Admin/ColouredActiveUsageSumaryPdf";
import UBIDashboardPDF from "../../pages/Admin/UBIDashboard/UBIDashboardPDF";
import ColouredoverAllSummaryPdf from "../ViewPdf/User/ColouredoverAllSummaryPdf";
import ColouredriskAlertSummaryPdf from "../ViewPdf/User/ColouredriskAlertSummaryPdf";
import ColouredcategorySummaryPdf from "../ViewPdf/User/ColouredcategorySummaryPdf";
import AdUnSafeDriversPdf from "../ViewPdf/Admin/unSafeDriversPdf";
import RideListAllSummaryPdf from "../ViewPdf/User/RideListAllSummaryPdf";
import UserRideListAllSummaryPdf from "../ViewPdf/User/UserRidesViewPdf";
import ViewPdfImage from "../../assets/images/common/ViewPDFImage.svg"
import ConformationPopupPdf from "../Common/ConfirmationPopupPdf";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
 
} from "reactstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "85%",
    height: "100%",
    borderRadius: 10,
    transform: "translate(-50%, -50%)",
  },
};

export default function ViewPdf({  
  ADTopRowItemsTemp,
  drivingDataList,
  selectedValue,
  ADRideBoxCount,
  filtersADOS,
  summaryData,
  summaryDataSingle,
  
  getPageNumber,
  getTotalPageCount,
  getTotalUserData,
  getSummaryDataList,
  getAssAndDecValue,
  getSearchText,

  riskDataListUser,
  drivingScoreDataUser,
  drivingDataListUser,
  showPeriodOneDate,
  showPeriodTwoDate,
  filtersUserPage,
  apiDataRideList,
  rideDetailsSummary,
  rideNameData,
  userRideApiData,
  totalRide,
  RideListPageNumber,
  RideListTotalPageCount,
  RideListWorkFilter,
  RideListfilter,
  RideListSearchText,
  RideListStartDateTime,
  RideListEndDateTime,


  totalUserRides,
  UserRidesPageNumber,
  UserRideTotalPageCount,
  UserRideWorkFilter,
  UserRideFilter,
  UserRideSearchText,
  UserRideStartDateTime,
  UserRideEndDateTime,

  rideDetailsRiskDataList,
  rideDetailsDistributionData,
  rideDetailsDriverStateList,
  rideDetailsDriverStyleList,
  rideDetailsRiskDataTable,

  aggregateData,
  totalDays,
  riskSummaryData,
  aggregateData1,
  totalDays1,
  receiveRideRisk,
  filtersADCS,
  filtersUDCS,
  topRow,
  categoryTable,
  errorDataWithinRange,
  // totalSafeRidePer,
  userUsagePer,
  usageHistory,
  safetycategory,
  topWidgetData,
  safetyData,
  drivingStatus,
  dayNightPercentage,
  filters,
  leaderBoardProps,
  overallDataProps,
  UDMainDashboardTopRowItemsTemp,
  totalSafeRidePer,
  UDMainDashboardSafetyTableData,
  yearlyDatas,
  USRideBoxCount,
  rideNamesPerCat,
  activeDriverPie,
  otherRiskData,
  DistributionData,
  driverStateList,
  driverStyleList,
  stressStrainData,
  riskDataList,
  overSpeedTableData,
  mobileUseTableData,
  mobileScreenTableData,
  riskDataTable,
  rideRiskData,
  stressData,
  aCBDataProps,
  locationDataPdf,
  safetyTableData1,
  sportiveTableData1,
  quiteSafeTableData1,
  recklessTableData1,
  quiteDangerousTableData1,
  dangerousTableData1,
  filteredScoreData,
  filteredHoursData,
  filteredDistanceData,
  drivStyleData,
  drivBehavData,
  aCatData,
  sDistData,
  sSCData,
  mUHData,
  rideCountRows,
  sPData,
  startDateTime,
  totalKmSPoint,
  yearlyDrivingScore,
  mobileUsagePoint,
  drivingBehaviorPoint,
  topRowStressStrainPoint,
  getSelectedYearData,
  lastOverAllSyData,
  yearlyActivities,
  yearlyFilter,
  time,
  filtersADRS,
  numberofRidessPdfData,
  activeDriverData,
  riskAlertPdf,
  rccProps,
  osMcuMsuProps,
  aCBProps,
  osDataProps,
  msuPdfDataProps,
  mcuPdfDataProps,
  section2filter,
  safetyPieData,
  ubiData,
  summaryDataUser,
  topWidgetDataPdf
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const CurrentDay = UtilDateTime.getCurrentDayformat();
  const [currentFilter, setCurrentFilter] = useState("CURRENT_YEAR");
  // const [receiveRideRisk, setReceiveRideRisk] = useState([]);
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

const [popupData, setPopUpData] = useState({
    Purpose: "",
    message: "",
    DataDetails: "",
  });

console.log(userRideApiData,"userRideApiData")
  const openModal = () => {
    if(apiDataRideList?.length == 0){     
      setConfirmationModalOpen(true);
      openPopup("");
    }else if(userRideApiData?.length == 0){
      setConfirmationModalOpen(true);
      openPopup("");
    }else{
      setIsModalOpen(true);
    }
    
  };

  const closeModal = () => {
    setIsModalOpen(false);   
  };  

  const handlePdfPageOpen  = () => {
    setIsModalOpen(true);
    setConfirmationModalOpen(false)
  }

  

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const openPopup = (PopupType, Data) => {
      
    setPopUpData({
      ...popupData,
      Purpose: "Delete",
      message: "There is no record in the current page to generate PDF. Are you sure want to generate PDF?",
      DataDetails: "",
    });
 
  // setOpen(true);
};



  useEffect(() => {
    // Add/remove class to body when modal is opened/closed
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup effect
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  const location = useLocation();
  return (
    <div className="App">
      {/* <button className="btn btnColorStylesNew btn-sm" onClick={openModal}>
        View / Download PDF
      </button> */}
      <div style={{backgroundColor:"#FFCF23",cursor:"pointer",display:"flex",justifyContent:"center",flexDirection:"column",height:"28px",borderRadius:"0.2rem"}} onClick={openModal}>
      
      <div style={{display:"flex"}}>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <span style={{marginLeft:"5px",fontWeight:700,fontSize:'12px'}} className="btnColorStylesNew">Current Page</span>
      </div>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <img src={ViewPdfImage} style={{ height: "20px", width: "41px",marginLeft:"3px",marginRight:"3px"}} />
      </div>
      </div>
     
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} style={customStyles} ariaHideApp={false}>
          {(location.pathname === "/user-dashboard" ||
            location.pathname === "/dashboard") && (
            <h5 style={{ position: "absolute", top: "10px" }}>
              motivai_{userName}_user_dashboard_{CurrentDay}.pdf{" "}
            </h5>
          )}
          {location.pathname === "/ridedetails" && (
            <h5 style={{ position: "absolute", top: "10px" }}>
              motivai_{userName}_ride_detail_{CurrentDay}.pdf{" "}
            </h5>
          )}
          {location.pathname === "/adminDashboard" && (
            <h5 style={{ position: "absolute", top: "10px" }}>
              motivai_{userName}_admin_dashboard_{CurrentDay}.pdf{" "}
            </h5>
          )}
          {location.pathname === "/drivingEfficeiency" && (
            <h5 style={{ position: "absolute", top: "10px" }}>
              motivai_{userName}_ubi_dashboard_{CurrentDay}.pdf{" "}
            </h5>
          )}

          <button
            className="btn btn-secondary btn-sm text-white"
            style={{ position: "absolute", top: "5px", right: "10px" }}
            onClick={closeModal}
          >
            Close
          </button>
          {location.pathname === "/ridedetails" ? (
            <RideDetailPdfNew
            rideDetailsSummary={rideDetailsSummary}
            rideNameData={rideNameData}
            rideDetailsRiskDataList={rideDetailsRiskDataList}
            rideDetailsDistributionData={rideDetailsDistributionData}
            rideDetailsDriverStateList={rideDetailsDriverStateList}
            rideDetailsDriverStyleList={rideDetailsDriverStyleList}
            rideDetailsRiskDataTable={rideDetailsRiskDataTable}
            />
          ) :          
            location.pathname === "/adminDashboard" ? (
              <AdOverallSummaryyPdf
                ADTopRowItemsTemp={ADTopRowItemsTemp}
                drivingDataList={drivingDataList}
                ADRideBoxCount={ADRideBoxCount}
                summaryData={summaryData}
                riskSummaryData={riskSummaryData}
                aggregateData1={aggregateData1}
                totalDays1={totalDays1}
                filtersADOS={filtersADOS}
                selectedValue={selectedValue}
                getPageNumber={getPageNumber}
                getTotalPageCount={getTotalPageCount}
                getTotalUserData={getTotalUserData}
                getSummaryDataList={getSummaryDataList}
                getAssAndDecValue={getAssAndDecValue}
                getSearchText={getSearchText}
              />
            ) : location.pathname === "/adminDashboard/ActivityUsageSummary" ? (
              <ColouredActivityUsageSummaryPdf
                yearlyActivities={yearlyActivities}
                yearlyFilter={yearlyFilter}
                time={time}
                numberofRidessPdfData={numberofRidessPdfData}
                activeDriverData={activeDriverData}
              />
            ) : (location.pathname === "/user-dashboard/RiskAlertSummary" || location.pathname === "/dashboard/RiskAlertSummary" ) ? (
              <>
              {/* <RiskAlertSummaryPdf riskAlertPdf={riskAlertPdf} aCBDataProps={aCBDataProps} rccProps={rccProps} osMcuMsuProps={osMcuMsuProps} aCBProps={aCBProps} osDataProps={osDataProps} msuPdfDataProps={msuPdfDataProps} mcuPdfDataProps={mcuPdfDataProps} section2filter={section2filter} /> */}
              <ColouredriskAlertSummaryPdf riskAlertPdf={riskAlertPdf} aCBDataProps={aCBDataProps} rccProps={rccProps} osMcuMsuProps={osMcuMsuProps} aCBProps={aCBProps} osDataProps={osDataProps} msuPdfDataProps={msuPdfDataProps} mcuPdfDataProps={mcuPdfDataProps} section2filter={section2filter}/>
              </>
            )
              :  location.pathname === "/user-dashboard" ||
                location.pathname === "/dashboard" ? (
                // <UserReactToPdf
                //   safetyPieData={safetyPieData}
                //   osMcuMsuProps={osMcuMsuProps}
                //   aCBProps={aCBProps}
                //   leaderBoardProps={leaderBoardProps}
                // overallDataProps={overallDataProps}
                //   totalSafeRidePer={usertotalSafeRidePer}
                //   rccProps={rccProps}
                //   rideNamesPerCat={rideNamesPerCat}
                //   riskAlertPdf={riskAlertPdf}
                //   aCBDataProps={aCBDataProps}
                //   osDataProps={osDataProps}
                //   msuPdfDataProps={msuPdfDataProps}
                //   mcuPdfDataProps={mcuPdfDataProps}
                //   locationDataPdf={locationDataPdf}
                // />
                <>
                {/* <UdOverAllSummaryPdf
                  UDMainDashboardSafetyTableData={UDMainDashboardSafetyTableData}
                  yearlyDatas={yearlyDatas}
                  UDMainDashboardTopRowItemsTemp={UDMainDashboardTopRowItemsTemp}
                  USRideBoxCount={USRideBoxCount}
                  overallDataProps={overallDataProps}
                  locationDataPdf={locationDataPdf}
                  getSelectedYearData={getSelectedYearData}
                  lastOverAllSyData={lastOverAllSyData}
                  totalSafeRidePer={usertotalSafeRidePer}
                  aggregateData={aggregateData}
                  totalDays={totalDays}
                  filtersData={filtersData}
                /> */}
                <ColouredoverAllSummaryPdf
                summaryDataSingle={summaryDataSingle}
                riskDataListUser={riskDataListUser}
                drivingScoreDataUser={drivingScoreDataUser}
                drivingDataListUser={drivingDataListUser}
                showPeriodOneDate={showPeriodOneDate}
                showPeriodTwoDate={showPeriodTwoDate}
                filtersUserPage={filtersUserPage}
                  // UDMainDashboardSafetyTableData={UDMainDashboardSafetyTableData}
                  // yearlyDatas={yearlyDatas}
                  // UDMainDashboardTopRowItemsTemp={UDMainDashboardTopRowItemsTemp}
                  // USRideBoxCount={USRideBoxCount}
                  // overallDataProps={overallDataProps}
                  // locationDataPdf={locationDataPdf}
                  // getSelectedYearData={getSelectedYearData}
                  // lastOverAllSyData={lastOverAllSyData}
                  // totalSafeRidePer={totalSafeRidePer}
                  // aggregateData={aggregateData}
                  // totalDays={totalDays}
                  // filtersData={filters}
                  // summaryDataUser={summaryDataUser}
                />
                </>
              ) : location.pathname === "/ubi-dashboard" ? (
                <UBIDashboardPDF
                  filteredScoreData={filteredScoreData}
                  filteredHoursData={filteredHoursData}
                  filteredDistanceData={filteredDistanceData}
                  drivStyleData={drivStyleData}
                  drivBehavData={drivBehavData}
                  aCatData={aCatData}
                  sDistData={sDistData}
                  sSCData={sSCData}
                  mUHData={mUHData}
                  rideCountRows={rideCountRows}
                  sPData={sPData}
                  startDateTime={startDateTime}
                  totalKmSPoint={totalKmSPoint}
                  yearlyDrivingScore={yearlyDrivingScore}
                  mobileUsagePoint={mobileUsagePoint}
                  drivingBehaviorPoint={drivingBehaviorPoint}
                  topRowStressStrainPoint={topRowStressStrainPoint}
                />
              ) : location.pathname === "/crashView" ? (
                <CrashReportPdf topRow={topRow} errorDataWithinRange={errorDataWithinRange}/>
              ) : location.pathname === "/drivingEfficeiency" ? (
                <UBIDashboardPDF ubiData={ubiData}/> 
              ) : location.pathname === "/myRides"
              ? (<RideListAllSummaryPdf
                apiDataRideList={apiDataRideList}
                totalRide={totalRide}
                RideListPageNumber={RideListPageNumber}
                RideListTotalPageCount={RideListTotalPageCount}
                RideListWorkFilter={RideListWorkFilter}
                RideListfilter={RideListfilter}
                RideListSearchText={RideListSearchText}
                RideListStartDateTime={RideListStartDateTime}
                RideListEndDateTime={RideListEndDateTime}
                
                  /> ) : location.pathname == "/userRides" ? (<UserRideListAllSummaryPdf 

                    userRideApiData={userRideApiData}
                    totalUserRides={totalUserRides}
                    UserRidesPageNumber={UserRidesPageNumber}
                    UserRideTotalPageCount={UserRideTotalPageCount}
                    UserRideWorkFilter={UserRideWorkFilter}
                    UserRideFilter={UserRideFilter}
                    UserRideSearchText={UserRideSearchText}
                    UserRideStartDateTime={UserRideStartDateTime}
                    UserRideEndDateTime={UserRideEndDateTime}
                    
                    />) : location.pathname === "/adminDashboard/LowestPerformanceDrivers" ? (<AdUnSafeDriversPdf 
                summaryData={summaryData}                
                filtersADOS={filtersADOS}/>) : null}
        </Modal>
      )}

<Modal
                isOpen={confirmationModalOpen}
                onRequestClose={closeConfirmationModal}
                toggle={closeConfirmationModal}
                contentLabel="Confirmation Modal"
                // className="custom-modall"
                style={{
                  backgroundColor:"green",
                  content: { height: "190px", width: "340px", margin: "auto", borderRadius:"10px", padding:"0px" },
                  overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, // /* Black background with opacity */
                }}>


<ModalHeader style={{ height: "40px", backgroundColor: "#5156BE", width: "100%", display:"felx", justifyContent:"center"  }} >
  <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>Confirm Action </span>
</ModalHeader>
               
                 <ConformationPopupPdf
         Data={popupData}
         formSubmit={handlePdfPageOpen}
         cancelSubmit={closeConfirmationModal}
        />
              </Modal>
    </div>
  );
}
