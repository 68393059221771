import React, { useEffect, useState } from "react";
import PdfHeader from "../Components/pdfHeaderV1";
import {
  Document,
  Image,
  Line,
  Page,
  PDFViewer,
  Rect,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import PdfHeading from "../Components/pdfHeading";
import UtilDateTime from "../../../util/UtilDateTime";
import ValueFormat from "../../../util/ValueFormat";
import {
  SC_COMPANY_NAME,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import { useLocation } from "react-router-dom";
import PdfFooter from "../Components/pdfFooterV1";

import { displayText } from "../../../constant/MessageConstant";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";
import { Column } from "jspdf-autotable";

import rank from "../../../assets/images/adminDashboard/pdf/pdf_rank.png";
import firstName from "../../../assets/images/adminDashboard/pdf/pdf_firstname.png";
import username from "../../../assets/images/adminDashboard/pdf/pdf_username.png";
import efficiency from "../../../assets/images/adminDashboard/pdf/pdf_efficiency.png";
import totalRides from "../../../assets/images/adminDashboard/pdf/pdf_total_rides.png";
import drivingScore from "../../../assets/images/adminDashboard/pdf/pdf_driving_score.png";
import anticipation from "../../../assets/images/adminDashboard/pdf/pdf_anticipation.png";
import selfConfidence from "../../../assets/images/adminDashboard/pdf/pdf_self_confidence.png";
import drivingSkill from "../../../assets/images/adminDashboard/pdf/pdf_driving_skill.png";
import drivingStyle from "../../../assets/images/adminDashboard/pdf/pdf_driving_style.png";
import drivingState from "../../../assets/images/adminDashboard/pdf/pdf_driver_state.png";
import mobScreen from "../../../assets/images/adminDashboard/pdf/pdf_mobile_screen.png";
import mobCall from "../../../assets/images/adminDashboard/pdf/pdf_mobile_call.png";
import kmsDriven from "../../../assets/images/adminDashboard/pdf/pdf_kms_driven.png";
import stressStrain from "../../../assets/images/adminDashboard/pdf/pdf_stress_strain.png";
import tdistance from "../../../assets/images/adminDashboard/pdf_distance.png";
import tduration from "../../../assets/images/adminDashboard/pdf_duration.png";
import i5 from "../../../assets/images/adminDashboard/pdf_high_alerts.png";
import i6 from "../../../assets/images/adminDashboard/pdf_medium_alerts.png";
import i7 from "../../../assets/images/adminDashboard/pdf_overspeed.png";
import i8 from "../../../assets/images/adminDashboard/pdf_mobile_call.png";
import i9 from "../../../assets/images/adminDashboard/pdf_mobile_screen.png";
import i10 from "../../../assets/images/adminDashboard/pdf_acceleration.png";
import i11 from "../../../assets/images/adminDashboard/pdf_braking.png";
import i12 from "../../../assets/images/adminDashboard/pdf_cornering.png";
import crash from "../../../assets/images/adminDashboard/pdf/pdf_crash.png";


import i4 from "../../../assets/images/adminDashboard/totalRides.svg";
import UserNameIcon from "../../../assets/images/adminDashboard/pdf/userNameImagePdf.png";
import LocationIcon from "../../../assets/images/adminDashboard/pdf/locationIconPdf.png";
import MinsIcon from "../../../assets/images/adminDashboard/pdf/minsIconPdf.png";
import AndroidIcon from "../../../assets/images/adminDashboard/pdf/androidIconPdf.png";
import WheelerIcon from "../../../assets/images/adminDashboard/pdf/wheelerIconPdf.png";
import ActivedateIcon from "../../../assets/images/adminDashboard/pdf/ActiveDateIconPdf.png";
import LastRideIcon from "../../../assets/images/adminDashboard/pdf/lastRideDateIcon.png";
import AnticipationIcon from "../../../assets/images/adminDashboard/pdf/AnticipationIconPdf.png";
import SelfConfidenceIcon from "../../../assets/images/adminDashboard/pdf/SelfconfidenceIconPdf.png";
import DrivingSkillcon from "../../../assets/images/adminDashboard/pdf/DrivingskillIconPdf.png";
import DrivingStylelcon from "../../../assets/images/adminDashboard/pdf/DrivingstyleIcon.png";
import DrivingStatelcon from "../../../assets/images/adminDashboard/pdf/DrivingStateIcon.png";
import MobileUsagelcon from "../../../assets/images/adminDashboard/pdf/MobileUsageIcon.png";
import OverSpeedlcon from "../../../assets/images/adminDashboard/pdf/OverSpeedIcon.png";
import Abclcon from "../../../assets/images/adminDashboard/pdf/AbcIcon.png";
import Countlcon from "../../../assets/images/adminDashboard/pdf/countIcon.png";
import CallCountlcon from "../../../assets/images/adminDashboard/pdf/CallcountIcon.png";
import ScreenCountlcon from "../../../assets/images/adminDashboard/pdf/ScreencountIcon.png";
import steeringWheel from "../../../assets/images/adminDashboard/pdf/steeringWheelIcon.png";
import IosIcon from "../../../assets/images/adminDashboard/pdf/iosIconPdf.png";

import totalRidesIcon from "../../../assets/images/adminDashboard/pdf/totalRidesIcon.png";
import userIconPdf from "../../../assets/images/adminDashboard/pdf/userIconPdf.png";
import totalDistanceIcon from "../../../assets/images/adminDashboard/pdf/totalDistanceIcon.png";
import totalDurationIcon from "../../../assets/images/adminDashboard/pdf/totalDurationIcon.png";
import highRiskIconPdf from "../../../assets/images/adminDashboard/pdf/highRiskIconPdf.png";


import CardImg1 from "../../../assets/images/t_animal_crossing.png";
import Caution from "../../../assets/images/t_caution.png";
import Curve from "../../../assets/images/t_curves.png";
import Round from "../../../assets/images/Round-About.png";
import Hillimg from "../../../assets/images/t_hill.png";
import HillDownWards from "../../../assets/images/t_hill_downwards.png";
import hillUp from "../../../assets/images/t_hill_upwards.png";
import icyConditions from "../../../assets/images/t_icy_conditions.png";
import Intersection from "../../../assets/images/t_intersection.png";
import LaneMerge from "../../../assets/images/t_lane_merge.png";
import LowGearArea from "../../../assets/images/t_low_gear_area.png";
import NarrowRoad from "../../../assets/images/t_narrow_road.png";
import NoOverTaking from "../../../assets/images/t_no_overtaking.png";
import PedestrianCrossing from "../../../assets/images/t_pedestrian_crossing.png";
import Priority from "../../../assets/images/t_priority.png";
import RailwayCrossing from "../../../assets/images/t_railway_crossing.png";
import RiskOfGrounding from "../../../assets/images/t_risk_of_grouping.png";
import School from "../../../assets/images/t_school_zone.png";
import SlipperyRoads from "../../../assets/images/t_slippery_road.png";
import Stopimg from "../../../assets/images/t_stop.png";
import TrafficLight from "../../../assets/images/t_traffic_light.png";
import WindImg from "../../../assets/images/t_wind.png";
import WindingRoad from "../../../assets/images/t_winding_road.png";
import YieldImg from "../../../assets/images/t_yield.png";
import NormalImg from "../../../assets/images/Ride_Details/Normall.png";
import TiredImg from "../../../assets/images/Ride_Details/Tired.png";
import AngryImg from "../../../assets/images/Ride_Details/Angry.png";
import SportiveImge from "../../../assets/images/Ride_Details/Normal.png";
import EcoImg from "../../../assets/images/Ride_Details/Eco.png";
import ChaoticImg from "../../../assets/images/Ride_Details/Chaotic.png";
import AggressiveImg from "../../../assets/images/Ride_Details/Aggressive.png";
import PdfFooterV1 from "../Components/pdfFooterV1";

// const styles = StyleSheet.create({
//   pdfViewer: {
//     height: "96%",
//     width: "100%",
//     marginTop: "20px",
//     marginBottom: "5px",
//   },
//   document: {
//     marginTop: "15px",
//     marginBottom: "5px",
//   },
//   page: {
//     width: "100%",
//   },
//   tablestyle: {
//     border: "1px solid black",
//     borderTop: "none",
//     borderRight: "none",
//     padding: "6px",
//     fontSize: "10px",
//     textAlign: "center",
//   },
// });

const fontBold = {
  fontFamily: "Helvetica-Bold",
};

const styles = StyleSheet.create({
  gridContainer: {
    marginBottom: 0,
  },
  rowContainer: {
    flexDirection: 'row', // Arrange items horizontally
    justifyContent: 'space-between',
    gap:10,
    marginBottom: 10, // Space between rows
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    textAlign:"left",
    border:"1px solid lightgrey",
   
    
  },
  cardContent: {
    display: "flex",
    flexDirection:"row",
    justifyContent: "space-between",
    textAlign:"left",
    padding:5
  },
  imageContainer: {
    display:"flex",
    justifyContent:"center",
    flexDirection:"column"
  },
  image: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  titleContainer: {
    display:"flex",
    justifyContent:"center",
    flexDirection:"column", 
    width:"70%"
  },
  title: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  dataContainer: {},
  dataText: {
    fontSize: 12,
    color: '#555',
  },

  page: {
    padding: 30,
    paddingTop: 10,
  },
  header: {
    fontSize: 16,
    marginBottom: 15,
    marginTop:20
  },
  table: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
  // Cell styles with fixed width, height, column gap, and row gap
  cell: {
    width: '12%',  // Fixed width for columns (8 columns)
    height: 30,    // Fixed height for rows    
    marginRight: 5,  // Gap between columns   
    border: '1px solid lightgrey',
    borderTop:0,
    fontSize: 9,
    display: 'flex',
    flexDirection:"row",
    alignItems: 'center',
    justifyContent: 'center',
  },

  cellHeader: {
    width: '12%',  // Fixed width for columns (8 columns)
    height: 30,    // Fixed height for rows
    // padding: 5,
    marginRight: 5,  // Gap between columns
    // marginBottom: 5, // Gap between rows
    border: '1px solid lightgrey',   
    fontSize: 9,
    display: 'flex',
    flexDirection:"row",
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:globalPDF.GREY_COLOR,
    
  },
 
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },

  headerText:{
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
    textAlign:"center"
    
  },
  headerTextDNA:{
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
    textAlign:"center",
    color:"red"
    
  },
  mapText:{    
    fontSize: 9,
    textAlign:"center"
    
  }
});



const ColouredoverAllSummaryPdf = (
  {
    summaryDataSingle,
    riskDataListUser,
    drivingScoreDataUser,
    drivingDataListUser,
    showPeriodOneDate,
    showPeriodTwoDate,
    filtersUserPage
    // UDMainDashboardSafetyTableData,
    // yearlyDatas,
    // UDMainDashboardTopRowItemsTemp,
    // USRideBoxCount,
    // overallDataProps,
    // getSelectedYearData,
    // lastOverAllSyData,
    // totalSafeRidePer,
    // aggregateData,
    // filtersData,
    // totalDays,
    // summaryDataUser
  }
) => {
  // console.log(summaryDataUser.data, "summaryDataUser");

  const dataListNew = [
    {
      type: "ANIMAL_CROSSING",
      title: "Animal Crossing",
      overallData: 0,
      currentMonthGraphData: [0],
      image: CardImg1,
      index:1
    },
    {
      type: "CAUTION",
      title: "Caution",
      overallData: 0,
      currentMonthGraphData: [0],
      image: Caution,
      index:2
    },
    {
      type: "CURVE",
      title: "Curve",
      overallData: 0,
      currentMonthGraphData: [0],
      image: Curve,
      index:3
    },  
    {
      type: "HILL",
      title: "Hill",
      overallData: 0,
      currentMonthGraphData: [0],
      image: Hillimg,
      index:4
    },
    {
      type: "HILL_DOWN",
      title: "Hill Downwards",
      overallData: 0,
      currentMonthGraphData: [0],
      image: HillDownWards,
      index:5
    },
    {
      type: "HILL_UP",
      title: "Hill Upwards",
      overallData: 0,
      currentMonthGraphData: [0],
      image: hillUp,
      index:6
    },
    {
      type: "ICY",
      title: "Icy Conditions",
      overallData: 0,
      currentMonthGraphData: [0],
      image: icyConditions,
      index:7
    },
    {
      type: "INTERSECTION",
      title: "Intersection",
      overallData: 0,
      currentMonthGraphData: [0],
      image: Intersection,
      index:8
    },
    {
      type: "LANE_MERGE",
      title: "Lane Merge",
      overallData: 0,
      currentMonthGraphData: [0],
      image: LaneMerge,
      index:9
    },
    {
      type: "LOW_GEAR",
      title: "Low Gear Area",
      overallData: 0,
      currentMonthGraphData: [0],
      image: LowGearArea,
      index:10
    },
  
    {
      type: "NARROW_ROAD",
      title: "Narrow Road",
      overallData: 0,
      currentMonthGraphData: [0],
      image: NarrowRoad,
      index:11
    },
    {
      type: "OVERTAKING",
      title: "No Overtaking",
      overallData: 0,
      currentMonthGraphData: [0],
      image: NoOverTaking,
      index:12
    },
  
    {
      type: "CROSSING",
      title: "Pedestrian Crossing",
      overallData: 0,
      currentMonthGraphData: [0],
      image: PedestrianCrossing,
      index:13
    },
    {
      type: "PRIORITY",
      title: "Priority",
      overallData: 0,
      currentMonthGraphData: [0],
      image: Priority,
      index:14
    },
    {
      type: "RAILWAY",
      title: "Railway Crossing",
      overallData: 0,
      currentMonthGraphData: [0],
      image: RailwayCrossing,
      index:15
    },
    {
      type: "RISK_GROUNDING",
      title: "Risk Of Grounding",
      overallData: 0,
      currentMonthGraphData: [0],
      image: RiskOfGrounding,
      index:16
    },
    {
      type: "ROUND",
      title: "Round About",
      overallData: 0,
      currentMonthGraphData: [0],
      image: Round,
      index:17
    },
    {
      type: "SCHOOL",
      title: "School",
      overallData: 0,
      currentMonthGraphData: [0],
      image: School,
      index:18
    },
    {
      type: "SLIPPERY_ROADS",
      title: "Slippery Roads",
      overallData: 0,
      currentMonthGraphData: [0],
      image: SlipperyRoads,
      index:19
    },
    {
      type: "STOP",
      title: "Stop",
      overallData: 0,
      currentMonthGraphData: [0],
      image: Stopimg,
      index:20
    },
  
    {
      type: "TRAFFIC_LIGHT",
      title: "Traffic Light",
      overallData: 0,
      currentMonthGraphData: [0],
      image: TrafficLight,
      index:21
    },
    {
      type: "WIND",
      title: "Wind",
      overallData: 0,
      currentMonthGraphData: [0],
      image: WindImg,
      index:22
    },
    {
      type: "WINDING_ROAD",
      title: "Winding Road",
      overallData: 0,
      currentMonthGraphData: [0],
      image: WindingRoad,
      index:23
    },
    {
      type: "YIELD",
      title: "Yield",
      overallData: 0,
      currentMonthGraphData: [0],
      image: YieldImg,
      index:24
    },
  ];

  const pathname = useLocation();

  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  var currentDate = new Date();

  const heading = "User Dashboard (Overall Summary)";

  const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();
  
  const formatDate = (date) => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata", // IST timezone
      timeZoneName: "short", // Adds timezone abbreviation dynamically
    };
  
    // Use Intl.DateTimeFormat to format the date
    const formatter = new Intl.DateTimeFormat("en-IN", options);
    const formattedDate = formatter.formatToParts(date);
  
    // Extract date, time, and timezone parts
    const day = formattedDate.find((part) => part.type === "day")?.value;
    const month = formattedDate.find((part) => part.type === "month")?.value;
    const year = formattedDate.find((part) => part.type === "year")?.value;
    const hour = formattedDate.find((part) => part.type === "hour")?.value;
    const minute = formattedDate.find((part) => part.type === "minute")?.value;
    const dayPeriod = formattedDate.find((part) => part.type === "dayPeriod")?.value.toUpperCase(); // Capitalize AM/PM
    const timeZone = formattedDate.find((part) => part.type === "timeZoneName")?.value;
  
    // Format the output as DD/MM/YYYY HH:MM AM/PM TZ
    return `${day}/${month}/${year} ${hour}:${minute} ${dayPeriod} ${timeZone}`;
  };

  
  const formattedDate = formatDate(currentDate);
  

    let period = "";
    let startDate = "";
    let endDate = "";
    if (filtersUserPage.durationFilter === "CURRENT_YEAR") {
      period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
      startDate = UtilDateTime.getCurrentYear(filtersUserPage.startDateTime);
      endDate = "";
    } else if (filtersUserPage.durationFilter === "CURRENT_MONTH") {
      period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
      startDate = UtilDateTime.getCurrentMonth(filtersUserPage.startDateTime);
      endDate = "";
    } else if (filtersUserPage.durationFilter === "CURRENT_WEEK") {
      period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
      startDate = UtilDateTime.getCurrentWeek1(filtersUserPage.startDateTime);
      endDate = "";
    } else if (filtersUserPage.durationFilter === "CURRENT_DAY") {
      period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
      startDate = UtilDateTime.getCurrentDay(filtersUserPage.startDateTime);
      endDate = "";
    } else {
      period = ValueFormat.smallLettersFormat(filtersUserPage.durationFilter);
      startDate = filtersUserPage.startDateTime.split(" ")[0];
      endDate = filtersUserPage.endDateTime.split(" ")[0];
    }

  

    

  const periodFormat = [period,startDate,endDate];

  function splitArrayIntoChunks(arr) {
    const chunkSize = 2;
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  const [riskDataList, setRiskDataList] = useState(splitArrayIntoChunks(dataListNew) || []);


  const chunkArray = (arr, chunkSize) => {
    if (!Array.isArray(arr) || chunkSize <= 0) return [];
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

 
  const allWidgets = (riskDataListUser || []).flat();
  const limitedWidgets = allWidgets.slice(0, 24);

  // Chunk the 24 items into rows of 8
  const rows = chunkArray(limitedWidgets, 8);

  const defaultData = [
    { month: "Jan-2024", data: null },
    { month: "Feb-2024", data: null },
    { month: "Mar-2024", data: null },
    { month: "Apr-2024", data: null },
    { month: "May-2024", data: null },
    { month: "Jun-2024", data: null },
    { month: "Jul-2024", data: null },
    { month: "Aug-2024", data: null },
    { month: "Sep-2024", data: null },
    { month: "Oct-2024", data: null },
    { month: "Nov-2024", data: null },
    { month: "Dec-2024", data: null },
  ];
 
  // Replace default data with API data
  const firstUserData = drivingDataListUser[0];
  
  const apiData = [
    { month: showPeriodOneDate.format("MMM-YYYY"), drivingScore: firstUserData?.period1Summary?.calculatedDrivingScore, anticipation: firstUserData?.period1Summary?.p1Data?.anticipationScore, drivingSkill: firstUserData?.period1Summary?.p1Data?.drivingSkillScore, drivingStyle: firstUserData?.period1Summary?.p1Data?.drivingStyleScore, drivingState: firstUserData?.period1Summary?.p1Data?.drivingStateScore, selfConfidence: firstUserData?.period1Summary?.p1Data?.selfConfidenceScore, mobileUsage: firstUserData?.period1Summary?.p1Data?.mobileCallScore, overSpeed: firstUserData?.period1Summary?.p1Data?.overSpeedScore, accBrakCor: firstUserData?.period1Summary?.p1Data?.accBrakCorScore, rides: firstUserData?.period1Summary?.totalRideCount, hours: firstUserData?.period1Summary?.totlaDurationInMin, Kms: firstUserData?.period1Summary?.totalDistanceInKm},
    { month: showPeriodTwoDate.format("MMM-YYYY"), drivingScore: firstUserData?.period2Summary?.calculatedDrivingScore, anticipation: firstUserData?.period2Summary?.p2Data?.anticipationScore, drivingSkill: firstUserData?.period2Summary?.p2Data?.drivingSkillScore, drivingStyle: firstUserData?.period2Summary?.p2Data?.drivingStyleScore, drivingState: firstUserData?.period2Summary?.p2Data?.drivingStateScore, selfConfidence: firstUserData?.period2Summary?.p2Data?.selfConfidenceScore, mobileUsage: firstUserData?.period2Summary?.p2Data?.mobileCallScore, overSpeed: firstUserData?.period2Summary?.p2Data?.overSpeedScore, accBrakCor: firstUserData?.period2Summary?.p2Data?.accBrakCorScore, rides: firstUserData?.period2Summary?.totalRideCount, hours: firstUserData?.period2Summary?.totlaDurationInMin, Kms: firstUserData?.period2Summary?.totalDistanceInKm},
  ]
 ;
 

  
  const updatedData = defaultData.map((row) => {
    const apiRow = apiData.find((item) => item.month === row.month);
    
    return apiRow
      ? { month: row.month, data: apiRow }
      : { month: "", data: null };
  });



  const monthsWithData = updatedData.filter((row) => row.data !== null);
const monthsWithoutData = updatedData.filter((row) => row.data === null);

// Combine the months with data at the start and the months without data after
const finalData = [...monthsWithData, ...monthsWithoutData];

// Log the final data to verify the result


  

// 1. Split data into chunks of 56 rows per page
// const chunkSize = 10;
// const chunks = [];
// for (let i = 0; i < data.length; i += chunkSize) {
// chunks.push(data.slice(i, i + chunkSize));
// }

const chunksViolation = [];
  const firstChunkSizeViolation = 86;
  const subsequentChunkSizeViolation = 86;

  for (let i = 0; i < drivingScoreDataUser.length; i += subsequentChunkSizeViolation) {
    if (i === 0) {
      chunksViolation.push(drivingScoreDataUser.slice(0, firstChunkSizeViolation));
      i = firstChunkSizeViolation - subsequentChunkSizeViolation;
    } else {
      chunksViolation.push(drivingScoreDataUser.slice(i, i + subsequentChunkSizeViolation));
    }
  }

const chunkToColumns = (chunk, columnsPerRow = 8) => {
  
  const rows = [];
  for (let i = 0; i < chunk.length; i += columnsPerRow) {
    rows.push(chunk.slice(i, i + columnsPerRow));
  }
  return rows;
};


// Apply the chunking into 8 columns
const chunksWithColumns = chunksViolation.map(chunk => chunkToColumns(chunk));

 const originalData = summaryDataSingle.data || [
    {
      ABCPoint: "-",
      DSP: "-",
      DSTP: "-",
      drivingScore: "-",
      calculatedDrivingScore: "-",
      efficiency: "0",
      efficiencyTooltipValue: "0",
      firstName: "-",
      mobileScreenPointNew: "-",
      mobileUsePointNew: "-",
      overallAnticipation: "-",
      overallDrivingSkill: "-",
      drivingBehaviour: "-",
      overallSelfConfidence: "-",
      rank: "-",
      totalKmSPoint: "-",
      totalRides: "-",
      userId: "-",
      username: "-",
      tKiloMeterTV: "-",
      tTravelTimeTV: "-",
      totalHighRiskCount: "-",
      totalMediumRiskCount: "-",
      totalOverSpeedDurationTV: "-",
      totalOverSpeedCount: "-",
      totalMobileScreenScreenOnDurationTV: "-",
      totalMobileScreenScreenOnCount: "-",
      totalMobileUseInAcceptedDurationTV: "-",
      totalMobileUseInAcceptedCount: "-",
      totalAccelerationCount: "-",
      totalBrakingCount: "-",
      totalCorneringCount: "-",
    },
  ];    
  


  const formatTime = (timeString) => {        
    const lastRideTime = timeString.split(" ")[1];   
    const today = new Date().toISOString().split('T')[0];  
    const dateTimeString = `${today}T${lastRideTime}`;
  
    const date = new Date(dateTimeString);
    if(timeString.length > 1) {
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }
    return "-";
  };

  return (
    <React.Fragment>
      {/* {showLoader > 0 ? <LoaderPdf /> : ""} */}

      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
          {/* {chunks.map((chunk, pageIndex) => ( */}
            <Page 
            // key={pageIndex} 
            size="A2">
              <PdfHeader />
              <View style={{ padding: 30, paddingTop: 10 }}>
                 {/* <PdfHeading
                  heading={heading}
                  periodFormat={periodFormat}
                  reversedDateFormat={reversedDateFormat}
                  filtersUserPage={filtersUserPage}
                />  */}
              

                <View
                  style={{
                    marginBottom: 0,
                    paddingTop: 5,
                  }}
                >
                  <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                  <Text style={{ fontSize: 14, marginBottom: 5,fontFamily: "Helvetica-Bold" }}>
                  User Report
                  </Text>
                  </View>
                  <View style={{display:"flex",flexDirection:"row"}}>
                                                    <View>
                                                    <Text style={{ fontSize: 13, marginBottom: 5}}>
                                      1. Overall Summary
                                    </Text>
                                                    </View>
                  
                                                    <View style={{marginLeft:"5px"}}>
                                                    <Text style={{ fontSize: 13, marginBottom: 5}}>
                                                    {`(`}
                                    </Text>
                                                    </View>                                   
                                                    
                                    
                                                    
                                                   <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  
                                                                   
                                                                                      <View style={{display:"flex",flexDirection:"row"}}>     
                                                                   
                                                                                     <Text style={{ fontSize: "9px", marginBottom: 2,textAlign:"center"}}>
                                                                                     Duration:
                                                                                     </Text>
                                                                   
                                                                                     <Text style={{ fontSize: "9px", marginBottom: 2,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                                                                                      {filtersUserPage.durationFilter == "" ? "All" : filtersUserPage.durationFilter == "CUSTOM_DURATION" ? `${UtilDateTime.formatDateIntSlash(filtersUserPage.startDateTime)} - ${UtilDateTime.formatDateIntSlash(filtersUserPage.endDateTime)}` : ValueFormat.capitalizeFirstLetterAndFormat(filtersUserPage.durationFilter)}
                                                                                     </Text>
                                                                   
                                                                                    
                                                                   
                                                                                     </View>   
                                                                   
                                                                                   </View>      
                                    
                                                    
                  
                                                    <View style={{marginLeft:"1px"}}>
                                                    <Text style={{ fontSize: 13, marginBottom:1}}>
                                                    {`)`}
                                    </Text>
                                                    </View>
                                    
                                    
                                    
                                    
                                    
                                    
                                                      
                                                    
                                                    </View>
                  

                  {/* <Text style={{ fontSize: 10, marginBottom: 7 }}>
                    {displayText.DRIVER_BEHAVIOUR_DESCRIPTION}
                  </Text>
                   */}
                  <View
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      backgroundColor: globalPDF.GREY_COLOR,
                      // justifyContent:"space-between",
                      height: "65px",
                      marginTop: 10,
                      
                    }}
                  >
                    <View 
                    style={{width:"253px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>User</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Details</Text>
                                              </View>
                    </View>

                    

                    <View 
                    style={{width:"77px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Score</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"60px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Risk</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"133px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Risk</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"60px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Behaviour</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"133px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Behaviour</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"67px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Crash</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Probability</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"97px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Acc/Brak/Cor</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Counts</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"87px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Over Speed</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Data</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"87px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Mobile</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Usage</Text>
                                              </View>
                    </View>

                    <View 
                    style={{width:"77px",border:"1px solid lightgrey",padding:5,display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Red Alert</Text>
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Counts</Text>
                                              </View>
                    </View>

                  </View>

                  {originalData.map((apiData, ind) => (
                    <View  
                    key={ind}
                    >

                 
                  <View
                 
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      // justifyContent:"space-between",
                      height: "120px",
                      marginBottom:"0px",
                      border: "1px solid lightgrey",
                      // marginTop: 10,
                      
                    }}
                  >
                    
                    <View 
                    style={{width:"260px",height:"120px",padding:5,}}
                    >
                      <View style={{display:"flex",flexDirection:"row"}}>
                      <Image src={UserNameIcon} style={{ width: "15px", height: "15px" }} />
                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,color:"#5156BE",fontFamily: "Helvetica-Bold",marginTop:"2px"}}>
                        {apiData.firstName}
                        </Text>
                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,marginTop:"2px"}}>|</Text>
                      <Text style={{fontSize: 10,marginLeft:"5px",fontWeight:700,fontFamily: "Helvetica-Bold",marginTop:"2px"}}>
                        {apiData.username}
                        </Text>
                      <View style={{backgroundColor:"#D7EC23",marginLeft:"5px",padding:2}}>
                      <Text style={{fontSize: 10}}>
                        {apiData.status}
                        </Text>
                      </View>
                     
                      </View>

                      <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginTop:"5px"}}>
                        
                          <View style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                        <Image src={totalRides} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                          {apiData.totalRides}{" "}Rides
                          </Text>
                        </View>
                        </View>

                        <View>
                          <View style={{display:"flex",flexDirection:"row"}}>
                          <Text style={{fontSize: 9,marginLeft:"5px",color:"#00A74B"}}>Good</Text>
                          <Text style={{fontSize: 9,marginLeft:"30px"}}>
                            {apiData.goodDrivingCount == "NaN" ? "0" : apiData.goodDrivingCount}{" "}%
                            </Text>
                          <Text style={{fontSize: 9,marginLeft:"2px"}}>
                            {`(${apiData?.goodDrivingCountValue})`}
                            </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"3px"}}>
                          <Text style={{fontSize: 9,marginLeft:"5px",color:"#D1C600"}}>Quiet Safe</Text>
                          <Text style={{fontSize: 9,marginLeft:"10px"}}>
                            {apiData.quietSafeDrivingCount == "NaN" ? "0" : apiData.quietSafeDrivingCount}{" "}%
                            </Text>
                          <Text style={{fontSize: 9,marginLeft:"2px"}}>
                            {`(${apiData?.quietSafeDrivingCountValue})`}
                            </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"3px"}}>
                          <Text style={{fontSize: 9,marginLeft:"5px",color:"#F14A21"}}>Dangerous</Text>
                          <Text style={{fontSize: 9,marginLeft:"8px"}}>
                            {apiData.dangerousDrivingCount == "NaN" ? "0" : apiData.dangerousDrivingCount}{" "}%
                            </Text>
                          <Text style={{fontSize: 9,marginLeft:"2px"}}>
                            {`(${apiData?.dangerousDrivingCountValue})`}
                            </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"3px"}}>
                          <Text style={{fontSize: 9,marginLeft:"5px",color:"#CB0206"}}>Bad</Text>
                          <Text style={{fontSize: 9,marginLeft:"35px"}}>
                            {apiData.badDrivingCount == "NaN" ? "0" : apiData.badDrivingCount}{" "}%
                            </Text>
                          <Text style={{fontSize: 9,marginLeft:"2px"}}>
                            {`(${apiData?.badDrivingCountValue})`}
                            </Text>
                          </View>
                        </View>

                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                        <Image src={LocationIcon} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                          {apiData.tKiloMeter + " Kms"}
                          </Text>
                        </View>
                        <View style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:"5px"}}>
                        <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                        { 
                                    parseFloat(apiData.tTravelTime) < 60 
                                      ? `${parseFloat(apiData.tTravelTime).toFixed(2)} Mins` 
                                      : `${(parseFloat(apiData.tTravelTime) / 60).toFixed(2)} Hours` 
                                  }
                          </Text>
                        </View>

                        </View>
                      
                      </View>

                      <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between",marginTop:"10px"}}>
                        {apiData.deviceType == "ANDROID" ?
                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                         <Image src={AndroidIcon} style={{ width: "15px", height: "15px" }} />
                        </View> 
                         : 
                        <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Image src={IosIcon} style={{ width: "15px", height: "15px" }} />
                       </View>}


                        
                          <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <Image src={WheelerIcon} style={{ width: "15px", height: "15px" }} />
                        <Text style={{fontSize: 9,marginLeft:"3px",marginTop:"2px"}}>
                          {apiData.vehicleType ? apiData.vehicleType : "-"}
                              </Text>
                        </View>
                        </View>

                        <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <Image src={ActivedateIcon} style={{ width: "15px", height: "15px",marginTop:"5px" }} />
                        <View style={{marginLeft:"5px"}}>
                        <Text style={{fontSize: 9,color:"#5156BE"}}>Act.Date</Text>
                        <Text style={{fontSize: 9,marginTop:"5px",color:"#5156BE"}}>
                          {UtilDateTime.formatDateInt(apiData.activationDate)}
                          </Text>
                        </View>
                        </View>

                        <View style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                        <Image src={LastRideIcon} style={{ width: "15px", height: "15px",marginTop:"5px" }} />
                        <View style={{marginLeft:"5px"}}>
                        <Text style={{fontSize: 9,color:"#5156BE"}}>Last Ride: <Text style={{color:"#000000",fontSize: 9}}>
                          {apiData.lastRideName}
                          </Text></Text>
                        <Text style={{fontSize: 9,marginTop:"5px",color:"#5156BE"}}>
                          {apiData.lastRideData
                              ? `${UtilDateTime.formatDateTimeNew(apiData.lastRideData).split(" ")[0]} | ${formatTime(apiData.lastRideData)}`
                              : `- | -`}
                              </Text>
                        </View>
                        </View>

                      </View>
                    </View>

                    <View  style={{width:"80px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>
                          {apiData.totalRides > 0 ? apiData.calculatedDrivingScore : "-"}
                          </Text>
                        </View>
                        <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                       {apiData.totalRides > 0 ? (<Image src={steeringWheel} style={{ width: "15px", height: "15px" }} />) : ""} 
                        <Text style={{fontSize: 9,marginLeft:"5px",marginTop:"3px"}}>
                          {apiData.totalRides > 0 ? apiData.calculatedDrivingCategory : "-"}
                          </Text>
                        </View>                 
                    </View>


                    <View  style={{width:"60px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>
                          {ValueFormat.formatDecimalIfRounded(
                                      apiData.drivingScore
                                    )}
                                    </Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"140px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",}}
                    >           
                   
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={AnticipationIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Anticipation</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}>
                            {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.overallAnticipation
                                      )}`}
                                      </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={SelfConfidenceIcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Self Confidence</Text>
                          <Text style={{fontSize: 9,marginLeft:"10px"}}>
                            {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallSelfConfidence
                                      )}
                                      </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingSkillcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving Skill</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}> 
                            {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallDrivingSkill
                                      )}
                                      </Text>
                          </View>                                
                    </View>



                    <View  style={{width:"60px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>
                          {apiData.drivingBehaviourScore}
                          </Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"140px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center"}}
                    >           
                    {/* <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>{apiData.drivingBehaviourScore}</Text>
                      </View>      */}
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingStylelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving Style</Text>
                          <Text style={{fontSize: 9,marginLeft:"22px"}}>
                            {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.DSTP
                                      )}`}
                                      </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={DrivingStatelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Driving State</Text>
                          <Text style={{fontSize: 9,marginLeft:"21px"}}>
                            {ValueFormat.formatDecimalIfRounded(
                                        apiData.DSP
                                      )}
                                      </Text>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={MobileUsagelcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Mobile Usage</Text>
                          <Text style={{fontSize: 9,marginLeft:"19px"}}>
                            {apiData.mobileUsage}
                            </Text>
                          </View>     
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={OverSpeedlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>Over Speed</Text>
                          <Text style={{fontSize: 9,marginLeft:"26px"}}>
                            {ValueFormat.formatDecimalIfRounded(
                                        apiData.totalKmSPoint
                                      )}
                                      </Text>
                          </View>     
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                          <Image src={Abclcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"5px"}}>ABC</Text>
                          <Text style={{fontSize: 9,marginLeft:"56px"}}>
                            {ValueFormat.formatDecimalIfRounded(
                                        apiData.ABCPoint
                                      )}
                                      </Text>
                          </View>                                
                    </View>

                    <View  style={{width:"70px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       
                    <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"12px",fontWeight:700,fontFamily: "Helvetica-Bold"}}>-</Text>
                      </View>   

                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:"8px"}}>
                      
                        <Text style={{fontSize:"10px"}}>L0 is Safe</Text>
                        </View>  
                        <View style={{display:"flex",flexDirection:"row",justifyContent:"center",marginTop:"8px"}}>
                      
                        <Text style={{fontSize:"10px"}}>L6 is Unsafe</Text>
                        </View>                 
                    </View>

                    <View 
                    style={{width:"100px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                            <View style={{width:"25%"}}>
                          <Image src={DrivingStylelcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View >
                          <Text style={{fontSize: 9,marginTop:"2px"}}>Acc</Text>
                          </View>
                          <View >
                          <Text style={{fontSize: 9,marginLeft:"10px",marginTop:"2px"}}> 
                            {apiData.totalAccelerationCount}
                            </Text>
                            </View>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                          <View style={{width:"25%"}}>
                          <Image src={DrivingStatelcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>Brak</Text>
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginLeft:"10px",marginTop:"2px"}}>
                            {apiData.totalBrakingCount}
                            </Text>
                            </View>
                          </View>
                          <View style={{display:"flex",flexDirection:"row",marginTop:"5px",justifyContent:"center"}}>
                          <View style={{width:"25%"}}>
                          <Image src={MobileUsagelcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>Cor
                          </Text>
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginLeft:"10px",marginTop:"2px"}}>
                            {apiData.totalCorneringCount}
                            </Text>
                            </View>
                          </View>     
                                                      
                    </View>


                    <View 
                    style={{width:"90px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                            <View style={{width:"25px"}}>
                          <Image src={Countlcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginTop:"3px",width:"48px"}}>
                            {apiData.totalOverSpeedCount}{" "}{"Count"}
                            </Text>
                            </View>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px",justifyContent:"center"}}>
                          <View style={{width:"10px"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View>
                          <Text style={{fontSize: 9,marginLeft:"15px",marginTop:"2px"}}>
                            {apiData.totalOverSpeedDuration}{" "}{"Mins"}
                            </Text>
                            </View>
                         
                          </View>
                          
                                                      
                    </View>

                    <View 
                    style={{width:"90px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                            <View style={{width:"20px"}}>
                          <Image src={ScreenCountlcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View style={{width:"48px"}}>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>
                            {apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}
                            </Text>
                            </View>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px",justifyContent:"center"}}>
                          <View style={{width:"20px"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View style={{width:"48px"}}>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>
                            {apiData.totalMobileScreenScreenOnDuration}{" "}{"Mins"}
                            </Text>
                            </View>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8x",justifyContent:"center"}}>
                          <View style={{width:"20px"}}>
                          <Image src={CallCountlcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View style={{width:"48px"}}>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>
                            {apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}
                            </Text>
                            </View>
                         
                          </View>

                          <View style={{display:"flex",flexDirection:"row",marginTop:"8px",justifyContent:"center"}}>
                          <View style={{width:"20px"}}>
                          <Image src={MinsIcon} style={{ width: "15px", height: "15px" }} />
                          </View>
                          <View style={{width:"48px"}}>
                          <Text style={{fontSize: 9,marginTop:"2px"}}>
                            {apiData.totalMobileUseInAcceptedDuration}{" "}{"Mins"}
                            </Text>
                            </View>
                         
                          </View>
                          
                                                      
                    </View>

                    <View 
                    style={{width:"80px",height:"120px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}
                    >              
                          <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                          <Image src={Countlcon} style={{ width: "15px", height: "15px" }} />
                          <Text style={{fontSize: 9,marginLeft:"8px",marginTop:"2px"}}>
                            {apiData.totalHighRiskCount}{" "}{"Count"}
                            </Text>
                         
                          </View>

                          
                          
                                                      
                    </View>


                  </View>
                  </View>
                   ))}

                 
                 
                </View>

                <View style={{display:"flex",flexDirection:"row",marginTop:"15px",marginBottom:10}}>
                                                    <View>
                                                    <Text style={{ fontSize: 13, marginBottom: 5}}>
                                      2. Risk Alerts
                                    </Text>
                                                    </View>
                  
                                                    <View style={{marginLeft:"5px"}}>
                                                    <Text style={{ fontSize: 13, marginBottom: 5}}>
                                                    {`(`}
                                    </Text>
                                                    </View>                                   
                                                    
                                    
                                                    
                                                   <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  
                                                                   
                                                                                      <View style={{display:"flex",flexDirection:"row"}}>     
                                                                   
                                                                                     <Text style={{ fontSize: "9px", marginBottom: 2,textAlign:"center"}}>
                                                                                     Duration:
                                                                                     </Text>
                                                                   
                                                                                     <Text style={{ fontSize: "9px", marginBottom: 2,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                                                                                      {filtersUserPage.durationFilter == "" ? "All" : filtersUserPage.durationFilter == "CUSTOM_DURATION" ? `${UtilDateTime.formatDateIntSlash(filtersUserPage.startDateTime)} - ${UtilDateTime.formatDateIntSlash(filtersUserPage.endDateTime)}` : ValueFormat.capitalizeFirstLetterAndFormat(filtersUserPage.durationFilter)}
                                                                                     </Text>
                                                                   
                                                                                    
                                                                   
                                                                                     </View>   
                                                                   
                                                                                   </View>      
                                    
                                                    
                  
                                                    <View style={{marginLeft:"1px"}}>
                                                    <Text style={{ fontSize: 13, marginBottom:1}}>
                                                    {`)`}
                                    </Text>
                                                    </View>
                                    
                                    
                                    
                                    
                                    
                                    
                                                      
                                                    
                                                    </View>

                

            <View>
              {/* {riskDataListleng === true ? (
                ""
              ) : riskDataListleng === false ? (
                <p className="data_not_available mb-2">
                  {displayText.DATA_NOT_AVAILABLE}
                </p>
              ) : riskDataListleng === "failed" ? (
                <p className="data_not_available mb-2">{displayText.ER_1206}</p>
              ) : (
                <p className="fetch_data mb-2">{displayText.FETCHING_DATA}</p>
              )} */}

<View style={styles.gridContainer}>
      {rows.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.rowContainer}>
          {row.map((widget, widgetIndex) => (
            <View key={widgetIndex} style={styles.cardContainer}>
              <View style={styles.cardContent}>
                {/* Image */}
                <View style={styles.imageContainer}>
                  <Image src={widget.image} style={styles.image} />
                </View>

                {/* Title */}
                <View style={styles.titleContainer}>
                  <Text style={styles.title}>{widget.title}</Text>
                </View>

                {/* Data */}
                <View style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                  <Text style={styles.dataText}>{widget.overallData ? widget.overallData : 0}</Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      ))}
    </View>
              
            </View>

            <View style={{marginTop:"5px"}}>
              
                
                  <View className="cardHeader" style={{padding:"5px"}}>
                    <Text style={{fontSize:13}}>
                     3. Month Wise Trends                  
                    </Text>
                  </View>
                
            </View>

            <View
                    style={{
                      // display:'flex',
                      flexDirection: "row",
                      backgroundColor: globalPDF.GREY_COLOR,
                      // justifyContent:"space-between",
                      height: "35px",
                      marginTop: 10,
                      
                    }}
                  >
                    <View 
                    style={{width:"60%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Month</Text>
                                              
                                              </View>
                    </View>

                    

                    <View 
                    style={{width:"220%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Score</Text>
                                             
                                              </View>
                    </View>

                    <View 
                    style={{width:"100%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Anticipation</Text>
                                              
                                              </View>
                    </View>

                    <View 
                    style={{width:"100%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Skill</Text>
                                              
                                              </View>
                    </View>

                    <View 
                    style={{width:"100%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving Style</Text>
                                              
                                              </View>
                    </View>

                    <View 
                    style={{width:"100%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Driving State</Text>
                                             
                                              </View>
                    </View>

                    <View 
                    style={{width:"100%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Self Confidence</Text>
                                              
                                              </View>
                    </View>

                    <View 
                    style={{width:"100%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Mobile Usage</Text>
                                              
                                              </View>
                    </View>

                    <View 
                    style={{width:"100%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Over Speed</Text>
                                             
                                              </View>
                    </View>

                    <View 
                    style={{width:"100%",border:"1px solid lightgrey",display:"flex",flexDirection:"row",justifyContent:"center",borderLeft:"0px"}}>
                    <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                   
                                              <Text style={{fontSize: 10,fontFamily: "Helvetica-Bold"}}>Acc/Brak/Cor</Text>
                                             
                                              </View>
                    </View>

                   

                  </View>

                  <View
                    
                    style={{
                     
                      flexDirection: "row",
                      
                      height: "21px",
                      marginBottom:"0px",
                      borderRight:"1px solid lightgrey",
                      borderLeft:"1px solid lightgrey",
                      borderBottom: "1px solid lightgrey",
                      
                    }}
                  >
                    

                    <View 
                    style={{width:"59%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",}}
                    >
                      <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>              
                      <Text style={{fontSize: 10}}>
                      
                        </Text>                     
                      </View>
                     
                    </View>
                    

                    <View  style={{width:"220%",height:"20px",padding:0,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                    <View style={{width:"100%",display:"flex",flexDirection:"row",height:"70px",justifyContent:"center",textAlign:"center"}}>

                        <View style={{width:"100%",borderRight:"1px solid lightgrey",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Text style={{fontSize: 9,fontFamily: "Helvetica-Bold"}}>Total
                           
                          </Text>
                        </View>

                        <View style={{width:"100%",borderRight:"1px solid lightgrey",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Text style={{fontSize: 9,fontFamily: "Helvetica-Bold"}}>Rides
                          
                          </Text>
                          </View>

                          <View style={{width:"100%",borderRight:"1px solid lightgrey",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                          <Text style={{fontSize: 9,fontFamily: "Helvetica-Bold"}}>Hours
                          
                          </Text>
                          </View>

                          <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                          <Text style={{fontSize: 9,fontFamily: "Helvetica-Bold"}}>Kms
                          
                          </Text>
                        </View>
                        </View>        

                                
                    </View>

                    <View  style={{width:"100%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                    <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"12px",fontWeight:700,}}>
                      
                        </Text>
                      </View>
                          
                       
                    </View>

                    <View  style={{width:"100%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,}}>
                          
                          </Text>
                        </View>
                                    
                    </View>

                    <View  style={{width:"100%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,}}>
                       
                          </Text>
                        </View>
                                       
                    </View>

                    <View  style={{width:"100%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,}}>
                       
                          </Text>
                        </View>
                                   
                    </View>

                    <View  style={{width:"100%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,}}>
                       
                          </Text>
                        </View>
                                      
                    </View>

                    <View  style={{width:"100%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,}}>
                       
                          </Text>
                        </View>
                                     
                    </View>

                    <View  style={{width:"100%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700,}}>
                        
                          </Text>
                        </View>
                                     
                    </View>

                    <View  style={{width:"100%",height:"20px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:"1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"12px",fontWeight:700}}>
                        
                          </Text>
                        </View>
                                    
                    </View>

                    

                    
                    





                  


                  </View>


                  {finalData.map((row, index) => (
                  <View
                     key={index}
                    style={{
                     
                      flexDirection: "row",
                      
                      height: "31px",
                      marginBottom:"0px",
                      borderRight:"1px solid lightgrey",
                      borderLeft:"1px solid lightgrey",
                      borderBottom:(index === (finalData.length - 1)) || (row.month) ? "1px solid lightgrey" : "",
                      
                    }}
                  >
                    

                    <View 
                    style={{width:"59%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",}}
                    >
                      <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>              
                      <Text style={{fontSize: 10}}>
                      {row.month}
                        </Text>                     
                      </View>
                     
                    </View>
                    

                    <View  style={{width:"220%",height:"30px",padding:0,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                    <View style={{width:"100%",display:"flex",flexDirection:"row",height:"70px",justifyContent:"center",textAlign:"center"}}>

                        <View style={{width:"100%",borderRight:row.month && "1px solid lightgrey",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Text style={{fontSize: 9,fontFamily: "Helvetica-Bold"}}>
                           {row?.data?.drivingScore}
                          </Text>
                        </View>

                        <View style={{width:"100%",borderRight:row.month && "1px solid lightgrey",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <Text style={{fontSize: 9}}>
                          {row?.data?.rides}
                          </Text>
                          </View>

                          <View style={{width:"100%",borderRight:row.month && "1px solid lightgrey",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                          <Text style={{fontSize: 9}}>
                          {row?.data?.hours}
                          </Text>
                          </View>

                          <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center"}}>
                          <Text style={{fontSize: 9}}>
                          {row?.data?.Kms}
                          </Text>
                        </View>
                        </View>        

                                
                    </View>

                    <View  style={{width:"100%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                    <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                      <Text style={{fontSize:"9px",fontWeight:700,}}>{row?.data?.anticipation}
                      
                        </Text>
                      </View>
                          
                       
                    </View>

                    <View  style={{width:"100%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"9px",fontWeight:700,}}>
                          {row?.data?.drivingSkill}
                          </Text>
                        </View>
                                    
                    </View>

                    <View  style={{width:"100%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"9px",fontWeight:700,}}>
                        {row?.data?.drivingStyle}
                          </Text>
                        </View>
                                       
                    </View>

                    <View  style={{width:"100%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"9px",fontWeight:700,}}>
                        {row?.data?.drivingState}
                          </Text>
                        </View>
                                   
                    </View>

                    <View  style={{width:"100%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"9px",fontWeight:700,}}>
                        {row?.data?.selfConfidence}
                          </Text>
                        </View>
                                      
                    </View>

                    <View  style={{width:"100%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"9px",fontWeight:700,}}>
                        {row?.data?.mobileUsage}
                          </Text>
                        </View>
                                     
                    </View>

                    <View  style={{width:"100%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"9px",fontWeight:700,}}>
                        {row?.data?.overSpeed}
                          </Text>
                        </View>
                                     
                    </View>

                    <View  style={{width:"100%",height:"30px",padding:5,display:"flex",flexDirection:"column",justifyContent:"center",borderLeft:row.month && "1px solid lightgrey"}}>
                       <View style={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                      
                        <Text style={{fontSize:"9px",fontWeight:700}}>
                        {row?.data?.accBrakCor}
                          </Text>
                        </View>
                                    
                    </View>

                    

                    
                    





                  


                  </View>
                  ))}


            

              </View>

              <PdfFooterV1
                pageNumber={1}
                totalPages={chunksWithColumns.length ? chunksWithColumns.length + 1 : 2}
                reversedDateFormat={formattedDate}
              />
            </Page>

            {
      chunksWithColumns.length > 0 ? 
              
      (chunksWithColumns.map((chunk, pageIndex) => (
      <Page key={pageIndex} size="A2" >
        <PdfHeader />
        
        <View style={{ padding: 30, paddingTop: 5 }}>
      {/* <PdfHeading
        heading={heading}
        periodFormat={periodFormat}
        reversedDateFormat={reversedDateFormat}
      /> */}
  <View style={{display:"flex",flexDirection:"row",marginBottom: 15, marginTop:20}}>
                                                    <View>
                                                    <Text style={{ fontSize: 13, marginBottom: 5}}>
                                      4. Driving Score Comparison
                                    </Text>
                                                    </View>
                  
                                                    <View style={{marginLeft:"5px"}}>
                                                    <Text style={{ fontSize: 13, marginBottom: 5}}>
                                                    {`(`}
                                    </Text>
                                                    </View>                                   
                                                    
                                    
                                                    
                                                   <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>  
                                                                   
                                                                                      <View style={{display:"flex",flexDirection:"row"}}>     
                                                                   
                                                                                     <Text style={{ fontSize: "9px", marginBottom: 2,textAlign:"center"}}>
                                                                                     Duration:
                                                                                     </Text>
                                                                   
                                                                                     <Text style={{ fontSize: "9px", marginBottom: 2,textAlign:"center",marginLeft:"3px",fontFamily: "Helvetica-Bold"}}>
                                                                                      {filtersUserPage.durationFilter == "" ? "All" : filtersUserPage.durationFilter == "CUSTOM_DURATION" ? `${UtilDateTime.formatDateIntSlash(filtersUserPage.startDateTime)} - ${UtilDateTime.formatDateIntSlash(filtersUserPage.endDateTime)}` : ValueFormat.capitalizeFirstLetterAndFormat(filtersUserPage.durationFilter)}
                                                                                     </Text>
                                                                   
                                                                                    
                                                                   
                                                                                     </View>   
                                                                   
                                                                                   </View>      
                                    
                                                    
                  
                                                    <View style={{marginLeft:"1px"}}>
                                                    <Text style={{ fontSize: 13, marginBottom:1}}>
                                                    {`)`}
                                    </Text>
                                                    </View>
                                    
                                    
                                    
                                    
                                    
                                    
                                                      
                                                    
                                                    </View>

        <View style={styles.row}>
              {/* Render cells for each row */}
              {Array.from({ length: 8 }).map((_, index) => (
                <View key={index} style={styles.cellHeader}>
                 

                    <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>

                    <Text style={styles.headerText}>Ride</Text>

                    </View>

                    <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>

                    <Text style={styles.headerText}>Driving Score</Text>

                    </View>
                    

                  

                  
                  
                </View>
                 ))}
         
                </View>

        <View style={styles.table}>
          {chunk.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.row}>
              {/* Render cells for each row */}
              {row.map((cell, cellIndex) => (
                <View key={cellIndex} style={styles.cell}>
                 

                    <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",borderRight:"1px solid lightgrey",height:"100%"}}>

                    <Text style={styles.mapText}>{cell?.rideName}</Text>

                    </View>

                    <View style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>

                    <Text style={styles.headerText}>{cell?.calculatedDrivingScore}</Text>

                    </View>

                  

                  
                  
                </View>
              ))}
              {/* If the row has fewer than 8 columns, fill empty cells to maintain grid */}
              {row.length < 8 &&
                Array.from({ length: 8 - row.length }).map((_, emptyIndex) => (
                  <View key={`empty-${emptyIndex}`} style={styles.cell} />
                ))}
            </View>
          ))}
        </View>
        </View>

        <PdfFooterV1
               pageNumber={pageIndex + 2}
               totalPages={chunksWithColumns.length + 1}
               reversedDateFormat={formattedDate}
              />
      </Page>
    ))) :
    (
      <Page size="A2" >
        <PdfHeader />
        
        <View style={{ padding: 30, paddingTop: 5 }}>
      {/* <PdfHeading
        heading={heading}
        periodFormat={periodFormat}
        reversedDateFormat={reversedDateFormat}
      /> */}

        <Text style={styles.header}>Driving Score Comparison</Text>
        
        <View style={styles.table}>
        <Text style={styles.headerTextDNA}>Data Not available</Text>
          
        </View>
        </View>

        <PdfFooterV1
               pageNumber={2}
               totalPages={chunksWithColumns.length + 2}
               reversedDateFormat={formattedDate}
              />
      </Page>

    )
  }
         
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
};

export default ColouredoverAllSummaryPdf;
