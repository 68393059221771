import React, { useEffect, useRef, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown } from "react-icons/fa";
import Report from "../../controller/Report/Report";
import { Tooltip } from "@mui/material";
import filterClockIconDe from "../../assets/images/common/filterIconClockDeactive.svg"
import filterClockIcon from "../../assets/images/common/filterClockIcon.svg"
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";
import andIconNew from "../../assets/images/common/androidTableIcon.svg";
import iosIconNew from "../../assets/images/common/iosTableIcon.svg";
import FTwheelerIcon from "../../assets/images/common/twoFourWheelerIcon.svg";
import ActType from "../../assets/images/superAdminIcons/actType.svg";
import ActiveDate from "../../assets/images/superAdminIcons/ActiveDate.svg";
import MDTotal from "../../assets/images/superAdminIcons/MDTotal.svg";
import RideNumberIcon from "../../assets/images/superAdminIcons/RideList/rideNumber.svg";
import DistanceIcon from "../../assets/images/superAdminIcons/RideList/distanceIcon.svg";
import DurationIcon from "../../assets/images/superAdminIcons/RideList/durationIcon.svg";
import DayIcon from "../../assets/images/superAdminIcons/RideList/dayIcon.svg";
import NightIcon from "../../assets/images/superAdminIcons/RideList/nightIcon.svg";
import CategoryIcon from "../../assets/images/superAdminIcons/RideList/categoryIcon.svg";
import ActivityIcon from "../../assets/images/superAdminIcons/RideList/activityIcon.svg";
import DeviceIcon from "../../assets/images/superAdminIcons/RideList/deviceIcon.svg";
import StatusIcon from "../../assets/images/superAdminIcons/RideList/statusIcon.svg";
import EventIcon from "../../assets/images/superAdminIcons/RideList/eventIcon.svg";
import InsertModeIcon from "../../assets/images/superAdminIcons/RideList/insertModeIcon.svg";
import TimeZoneIcon from "../../assets/images/superAdminIcons/RideList/timeZoneIcon.svg";
import BatteryStartIcon from "../../assets/images/superAdminIcons/RideList/batteryStartIcon.svg";
import BattryEndIcon from "../../assets/images/superAdminIcons/RideList/battryEndIcon.svg";
import FileNameIcon from "../../assets/images/superAdminIcons/RideList/fileNameIcon.svg";
import FileStatusIcon from "../../assets/images/superAdminIcons/RideList/fileStatusIcon.svg";
import SummaryStatusIcon from "../../assets/images/superAdminIcons/RideList/summaryStatusIcon.svg";
import SummaryTimeIcon from "../../assets/images/superAdminIcons/RideList/summaryTimeIcon.svg";
import ScoreIcon from "../../assets/images/superAdminIcons/RideList/scoreIcon.svg";
import GradeIcon from "../../assets/images/superAdminIcons/RideList/gradeIcon.svg";
import RiskIcon from "../../assets/images/superAdminIcons/RideList/riskIcon.svg";
import BehaviorIcon from "../../assets/images/superAdminIcons/RideList/behaviorIcon.svg";

import CppIcon from "../../assets/images/superAdminIcons/RideList/cppIcon.svg";
import AnticipationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
import SelfConfidenceIcon from "../../assets/images/superAdminIcons/RideList/selfConfidenceIcon.svg";
import DrivingSkilIcon from "../../assets/images/superAdminIcons/RideList/drivingSkilIcon.svg";
import DrivingStyleIcon from "../../assets/images/superAdminIcons/RideList/drivingStyleIcon.svg";
import DrivingStateIcon from "../../assets/images/superAdminIcons/RideList/drivingStateIcon.svg";
import MobileUsageIcon from "../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg";
import OverspeedIcon from "../../assets/images/superAdminIcons/RideList/overspeedIcon.svg";
import AccBrakCorIcon from "../../assets/images/superAdminIcons/RideList/accBrakCorIcon.svg";
import TimingStartIcon from "../../assets/images/superAdminIcons/RideList/timingStartIcon.svg"; 
import TimingEndIcon from "../../assets/images/superAdminIcons/RideList/timingEndIcon.svg";

import CreatedIcon from "../../assets/images/superAdminIcons/RideList/createdIcon.svg";
import InsertedIcon from "../../assets/images/superAdminIcons/RideList/insertedIcon.svg";
import ModifiedIcon from "../../assets/images/superAdminIcons/RideList/modifiedIcon.svg";
import StartLatIcon from "../../assets/images/superAdminIcons/RideList/startLatIcon.svg";
import StartZipcodeIcon from "../../assets/images/superAdminIcons/RideList/startZipcodeIcon.svg";
import StartLocationIcon from "../../assets/images/superAdminIcons/RideList/startLocationIcon.svg";
import StartAddressIcon from "../../assets/images/superAdminIcons/RideList/startAddressIcon.svg";
import EndLatIcon from "../../assets/images/superAdminIcons/RideList/endLatIcon.svg";
import EndZipCodeIcon from "../../assets/images/superAdminIcons/RideList/endZipCodeIcon.svg";
import EndLocationIcon from "../../assets/images/superAdminIcons/RideList/endLocationIcon.svg";

import EndAddressIcon from "../../assets/images/superAdminIcons/RideList/endAddressIcon.svg";
import DataCountStartIcon from "../../assets/images/superAdminIcons/RideList/dataCountStartIcon.svg";
import DataCountEndIcon from "../../assets/images/superAdminIcons/RideList/dataCountEndIcon.svg";
import DataCountErrorIcon from "../../assets/images/superAdminIcons/RideList/dataCountErrorIcon.svg";
import AlertIcon from "../../assets/images/superAdminIcons/RideList/alertIcon.svg";
import StressStrainIcon from "../../assets/images/superAdminIcons/RideList/stressStrainIcon.svg";
import ManualIcon from "../../assets/images/superAdminIcons/RideList/manualIcon.svg";
import AlertLowIcon from "../../assets/images/superAdminIcons/RideList/alertLowIcon.svg";
import AlertMediumIcon from "../../assets/images/superAdminIcons/RideList/alertMediumIcon.svg";
import AlertHighIcon from "../../assets/images/superAdminIcons/RideList/alertHighIcon.svg";

import NegativeIcon from "../../assets/images/superAdminIcons/RideList/nagativeIcon.svg";
import ZeroIcon from "../../assets/images/superAdminIcons/RideList/zeroIcon.svg";
import Sloat1Icon from "../../assets/images/superAdminIcons/RideList/sloat1Icon.svg";
import Slot2Icon from "../../assets/images/superAdminIcons/RideList/slot2Icon.svg";
import Slot3Icon from "../../assets/images/superAdminIcons/RideList/slot3Icon.svg";
import Slot4Icon from "../../assets/images/superAdminIcons/RideList/slot4Icon.svg";
import Slot5Icon from "../../assets/images/superAdminIcons/RideList/slot5Icon.svg";
import Slot6Icon from "../../assets/images/superAdminIcons/RideList/slot6Icon.svg";
import Slot7Icon from "../../assets/images/superAdminIcons/RideList/slot7Icon.svg";
import SlotKmsIcon from "../../assets/images/superAdminIcons/RideList/slotKms.svg";
import Slot8Icon from "../../assets/images/superAdminIcons/RideList/slot8Icon.svg";
import Slot9Icon from "../../assets/images/superAdminIcons/RideList/slot9Icon.svg";
import Slot10Icon from "../../assets/images/superAdminIcons/RideList/slot10Icon.svg";
import AccLowIcon from "../../assets/images/superAdminIcons/RideList/accLowIcon.svg";
import AccMediumIcon from "../../assets/images/superAdminIcons/RideList/accMediumIcon.svg";


import AccHighIcon from "../../assets/images/superAdminIcons/RideList/accHighIcon.svg";
import BrakLowIcon from "../../assets/images/superAdminIcons/RideList/brakLowIcon.svg";
import BrakMediumIcon from "../../assets/images/superAdminIcons/RideList/brakMediumIcon.svg";
import BrakHighIcon from "../../assets/images/superAdminIcons/RideList/brakHighIcon.svg";
import CornLowIcon from "../../assets/images/superAdminIcons/RideList/cornLow.svg";
import CornMediumIcon from "../../assets/images/superAdminIcons/RideList/cornMedium.svg";
import CornHighIcon from "../../assets/images/superAdminIcons/RideList/cornHigh.svg";
import OverSpeedCountIcon from "../../assets/images/superAdminIcons/RideList/overSpeedCount.svg";
import OverSpeedDistanceIcon from "../../assets/images/superAdminIcons/RideList/overSpeedDistance.svg";
import OverSpeedDurationIcon from "../../assets/images/superAdminIcons/RideList/overSpeedDuration.svg";

import MobileCallCount from "../../assets/images/superAdminIcons/RideList/mcCount.svg";
import MobileCallDistance from "../../assets/images/superAdminIcons/RideList/mcDistance.svg";
import MobileCallDuration from "../../assets/images/superAdminIcons/RideList/mcDuration.svg";

import MobileScreenCount from "../../assets/images/superAdminIcons/RideList/msCount.svg";
import MobileScreenDistance from "../../assets/images/superAdminIcons/RideList/msDistance.svg";
import MobileScreenDuration from "../../assets/images/superAdminIcons/RideList/msDuration.svg";

import DrivingStateUnKnown from "../../assets/images/superAdminIcons/RideList/dStateUnknown.svg";
import DrivingStateMprmal from "../../assets/images/superAdminIcons/RideList/dStateNormal.svg";
import DrivingStateTired from "../../assets/images/superAdminIcons/RideList/dStateTried.svg";
import DrivingStateAngry from "../../assets/images/superAdminIcons/RideList/dStateAngry.svg";

import DrivingStyleUnKnown from "../../assets/images/superAdminIcons/RideList/dStyleUnKnown.svg";
import DrivingStylenormal from "../../assets/images/superAdminIcons/RideList/dStyleNormal.svg";
import DrivingStyleSportive from "../../assets/images/superAdminIcons/RideList/dStyleSportive.svg";
import DrivingStyleEco from "../../assets/images/superAdminIcons/RideList/dStyleEco.svg";
import DrivingStyleChaotic from "../../assets/images/superAdminIcons/RideList/dStyleChaotic.svg";
import DrivingStyleAggressive from "../../assets/images/superAdminIcons/RideList/dStyleAggressive.svg";

import AccidentIcon from "../../assets/images/superAdminIcons/RideList/accident.svg";
import AnimalCrossingIcon from "../../assets/images/superAdminIcons/RideList/animalCrossing.svg";
import Caution from "../../assets/images/superAdminIcons/RideList/caution.svg";
import Congestion from "../../assets/images/superAdminIcons/RideList/congestion.svg";
import Curve from "../../assets/images/superAdminIcons/RideList/curve.svg";
import Hill from "../../assets/images/superAdminIcons/RideList/hill.svg";

import HillDownwards from "../../assets/images/superAdminIcons/RideList/hillDownwards.svg";
import HillUpwards from "../../assets/images/superAdminIcons/RideList/hillUpwards.svg";
import IcyConditions from "../../assets/images/superAdminIcons/RideList/icyConditions.svg";
import Intersection from "../../assets/images/superAdminIcons/RideList/intersection.svg";
import LaneMerge from "../../assets/images/superAdminIcons/RideList/LaneMerge.svg";
import LowGearArea from "../../assets/images/superAdminIcons/RideList/LowGearArea.svg";

import NarrowRoad from "../../assets/images/superAdminIcons/RideList/NarrowRoad.svg";
import NoOvertaking from "../../assets/images/superAdminIcons/RideList/NoOvertaking.svg";
import NoOvertakingTrucks from "../../assets/images/superAdminIcons/RideList/NoOvertakingTrucks.svg";
import PadestrianCrossing from "../../assets/images/superAdminIcons/RideList/PadestrianCrossing.svg";
import Priority from "../../assets/images/superAdminIcons/RideList/Priority.svg";
import PriorityToOncommingTraffic from "../../assets/images/superAdminIcons/RideList/PriorityToOncommingTraffic.svg";

import RailwayCrossing from "../../assets/images/superAdminIcons/RideList/RailwayCrossing.svg";
import RiskOfGrounding from "../../assets/images/superAdminIcons/RideList/RiskOfGrounding.svg";
import RoundAbout from "../../assets/images/superAdminIcons/RideList/RoundAbout.svg";
import SchoolZone from "../../assets/images/superAdminIcons/RideList/SchoolZone.svg";
import SlipperyRoads from "../../assets/images/superAdminIcons/RideList/SlipperyRoads.svg";
import StopSign from "../../assets/images/superAdminIcons/RideList/StopSign.svg";

import TrafficLight from "../../assets/images/superAdminIcons/RideList/trafficLight.svg";
import TramwayCrossing from "../../assets/images/superAdminIcons/RideList/TramwayCrossing.svg";
import Wind from "../../assets/images/superAdminIcons/RideList/Wind.svg";
import WindingRoad from "../../assets/images/superAdminIcons/RideList/WindingRoad.svg";
import Yield from "../../assets/images/superAdminIcons/RideList/Yield.svg";



import SuperAdminDashboardCtrlr from "../../controller/SuperAdminCtrlr";
import noDeviceIcon from "../../assets/images/common/noDeviceIcon.svg";
import UtilDateTime from "../../util/UtilDateTime";
import Converter from "../../components/Common/EncodeDecode";

export default function AppRides() {
  const [showLoader, setShowLoader] = useState(false);  
   const [summaryDataList, setSummaryDataList] = useState([]);
  const [totalPageCount , setTotalPageCount] = useState(0);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);  
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [exceptionFilter, setExceptionFilter] = useState("");
  const [exceptionFilter2, setExceptionFilter2] = useState("");
  const [deviceModeFilter, setDeviceModeFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectUsername, setSelectUsername] = useState("")
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [nextShow, setNextShow] = useState(false);
  const [prevShow, setPrevShow] = useState(false);
  const [count, setCount] = useState(0);

  const [filters, setFilters] = useState({
    durationFilter: "ALL",
    startDateTime: "",
    endDateTime: "",
  });

  const [deviceTypefilter, setDeviceTypefilter] = useState({
    durationFilter:"",   
  });

 
  const [searchText, setSearchText] = useState("");
  const [searchRideNumber, setSearchRideNumber] = useState("");
  const inputRef = useRef();
  const [selectedValue, setSelectedValue] = useState("ALL_USER_LIST");

  const pageSize = 10;

  const handleChange = (filterType) => {
    setIndexStart(0)
    setPageNumber(1);
    setCount(0);
    setStartDateTime("");
    setEndDateTime("");
    setFilters({
      startDateTime: "",
      endDateTime: "",
      durationFilter: filterType,
    });
  };   

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(userIdTemp);
    setSelectUsername(firstName);
    // Do something with the selected option
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault();
      inputRef.current.click();
    }
  };

  const handleKeyPressRide = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault();
      inputRef.current.click();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getRideListSA(0,"");
    setPageNumber((current) => current - current + 1);
  };

  const handleUserClick = (type) => {
    
    setIndexStart(0);
    setPageNumber(1);
    const selectedMapping = {
      ALL_USER:"",
      REGISTERD_USER: "REGISTERD_USER_LIST",          
      DEACTIVATED_USER: "DEACTIVATED_USER_LIST",
      DELETED_USER: "DELETED_USER_LIST",
    };
    // console.log(selectedMapping[type],"gjhzxcxcvbnvcb")
    setSelectedValue(selectedMapping[type]);    
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [startDateTime,endDateTime,filter]);
 
  const getRideListSA = async (indexStartOfSet,searchClear) => {
    setShowLoader(true);
    var filtersData = (filters.durationFilter == "ALL") ? "" : filters.durationFilter;
    var searchData = (searchClear == "CLEAR") ? "" : searchText;
    var searchDataRide = (searchClear == "CLEAR_RIDE") ? "" : searchRideNumber;
    var result = await SuperAdminDashboardCtrlr.getV1SuperAdminRideList(
      indexStartOfSet,
      filtersData,
      deviceTypefilter.durationFilter,
      selectedValue,
      searchData,
      searchDataRide,
      exceptionFilter,
      exceptionFilter2,
      deviceModeFilter,
      filters.startDateTime,
      filters.endDateTime
    );
    
    try {

      var resultJSON = JSON.parse(result);
      
      var status = resultJSON.status;

      if (status == "SUCCESS") {

        setShowLoader(false)

        var rideListData = resultJSON.data;

        setDataLength(rideListData.length);

     

        var updatedRideData = [];

        rideListData.forEach((rideData) => {

          var userId              = ValueFormat.parseAsString(rideData.userId);
          var rideId              = ValueFormat.parseAsString(rideData.deviceId);

        
          var userDetail          = ValueFormat.parseAsObject(rideData.userDetail);
          var rideDetail          = ValueFormat.parseAsObject(rideData.rideDetail);
          var rideOtherDetail     = ValueFormat.parseAsObject(rideData.rideOtherDetail);
          var scoreDetail         = ValueFormat.parseAsObject(rideData.scoreDetail);
          var riskDataDetail      = ValueFormat.parseAsObject(rideData.riskDataDetail);
          var behaviourDataDetail = ValueFormat.parseAsObject(rideData.behaviourDataDetail);
          var timingDetail        = ValueFormat.parseAsObject(rideData.timingDetail);
          var locationDetail      = ValueFormat.parseAsObject(rideData.locationDetail);
          var deviceField         = ValueFormat.parseAsObject(locationDetail.deviceField);
          var lastDeviceField     = ValueFormat.parseAsObject(locationDetail.lastDeviceField);
          var dataCountDetail     = ValueFormat.parseAsObject(rideData.dataCountDetail);
          var alertSummaryDetail  = ValueFormat.parseAsObject(rideData.alertSummaryDetail);
          var riskSlotDetail      = ValueFormat.parseAsObject(rideData.riskSlotDetail);
          var stressStrainDataDetail = ValueFormat.parseAsObject(rideData.stressStrainDataDetail);
          var manualDataDetail       = ValueFormat.parseAsObject(rideData.manualDataDetail);
          var drivingStateDetail       = ValueFormat.parseAsObject(rideData.drivingStateDetail);
          var drivingStyleDetail       = ValueFormat.parseAsObject(rideData.drivingStyleDetail);
          var alertCountDetail       = ValueFormat.parseAsObject(rideData.alertCountDetail);

          var totalCount       = ValueFormat.parseAsObject(rideData.totalCount);

          setTotalPageCount(Math.ceil(totalCount / pageSize));
          setNextShow(totalCount - indexStartOfSet > pageSize);

          // User Details

          var firstName           = ValueFormat.parseAsString(userDetail.firstName);
          var username            = ValueFormat.parseAsString(userDetail.username);
          var deviceType          = ValueFormat.parseAsString(userDetail.deviceType);
          var vehicleType         = ValueFormat.parseAsString(userDetail.vehicleType);
          var usernameType        = ValueFormat.parseAsString(userDetail.usernameType);
          var createdAtToTimeZone = ValueFormat.parseAsString(userDetail.createdAtToTimeZone);
          var active              = ValueFormat.parseAsString(userDetail.active);
          var status              = ValueFormat.parseAsString(userDetail.status);

          var overallRideCount    = ValueFormat.parseAsInt(userDetail.overallRideCount);
          
          // Ride Details

          var rideName         = ValueFormat.parseAsString(rideData.name) || "-";
          var distanceInKm     = ValueFormat.parseAsString(rideDetail.distanceInKm) || "-";
          var durationInMin    = ValueFormat.parseAsString(rideDetail.durationInMin) || "-";
          var dayPercentage    = ValueFormat.parseAsString(rideDetail.dayPercentage) || "-";
          var nightPercentage  = ValueFormat.parseAsString(rideDetail.nightPercentage) || "-";

          // Attributes

          var category                          = ValueFormat.parseAsString(rideOtherDetail.category) || "-";
          var deviceMode                        = ValueFormat.parseAsString(rideOtherDetail?.journeyPurposeType) || "-";
          var rideOtherDetailStatus             = ValueFormat.parseAsString(rideOtherDetail.status) || "-";
          var insertMode                        = ValueFormat.parseAsString(rideOtherDetail.insertMode) || "-";
          var timeZoneCode                      = ValueFormat.parseAsString(rideOtherDetail.timeZoneCode) || "-";
          var batteryLevelStart                 = ValueFormat.parseAsString(rideOtherDetail.batteryLevelStart) || "-";
          var batteryLevelEnd                   = ValueFormat.parseAsString(rideOtherDetail.batteryLevelEnd) || "-";
          var deviceRawFileName                 = ValueFormat.parseAsString(rideOtherDetail.deviceRawFileName) || "-";
          var deviceRawFileStatus               = ValueFormat.parseAsString(rideOtherDetail.deviceRawFileStatus) || "-";
          var deviceSummaryStatus               = ValueFormat.parseAsString(rideOtherDetail.deviceSummaryStatus) || "-";
          var subType                           = ValueFormat.parseAsString(rideOtherDetail?.journeyAppUseType) || "-";
          var eventAM                           = ValueFormat.parseAsString(rideOtherDetail?.journeyDataCaptureType) || "";
          var attrDeviceType                    = ValueFormat.parseAsString(rideOtherDetail.deviceType) || "-";
          var deviceSummaryModifiedAtToTimeZone = ValueFormat.parseAsString(rideOtherDetail.deviceSummaryModifiedAtToTimeZone) || "-";

          // Score Details

          var calDrivingScore       = ValueFormat.parseAsString(scoreDetail.calDrivingScore) || "-";
          var calRiskScore          = ValueFormat.parseAsString(scoreDetail.calRiskScore) || "-";
          var calBehaviourScore     = ValueFormat.parseAsString(scoreDetail.calBehaviourScore) || "-";
          var crashProbabilityScore = ValueFormat.parseAsString(scoreDetail.crashProbabilityScore) || "-";
          var calDrivingCategory    = ValueFormat.parseAsString(scoreDetail.calDrivingCategory) || "-";
          
          // Risk Data

          var anticipationScore    = ValueFormat.parseAsString(riskDataDetail.anticipationScore) || "-";
          var selfConfidenceScore  = ValueFormat.parseAsString(riskDataDetail.selfConfidenceScore) || "-";
          var drivingSkillScore    = ValueFormat.parseAsString(riskDataDetail.drivingSkillScore) || "-";

          // Behaviour Data

          var calDrivingStyleScore    = ValueFormat.parseAsString(behaviourDataDetail.calDrivingStyleScore) || "-";
          var calDrivingStateScore    = ValueFormat.parseAsString(behaviourDataDetail.calDrivingStateScore) || "-";
          var calOverspeedScore       = ValueFormat.parseAsString(behaviourDataDetail.calOverspeedScore) || "-";
          var calAccBrakCorScore      = ValueFormat.parseAsString(behaviourDataDetail.calAccBrakCorScore) || "-";
          var calMobileCallScore      = ValueFormat.parseAsString(behaviourDataDetail.calMobileCallScore) || "-";
          var calMobileScreenScore    = ValueFormat.parseAsString(behaviourDataDetail.calMobileScreenScore) || "-";

          // Timings

          var startDateTimeToTimeZone    = ValueFormat.parseAsString(timingDetail.startDateTimeToTimeZone) || "-";
          var endDateTimeToTimeZone      = ValueFormat.parseAsString(timingDetail.endDateTimeToTimeZone) || "-";
          var timingsCreatedAtToTimeZone = ValueFormat.parseAsString(timingDetail.createdAtToTimeZone) || "-";
          var insertedAtToTimeZone       = ValueFormat.parseAsString(timingDetail.insertedAtToTimeZone) || "-";
          var modifiedAtToTimeZone       = ValueFormat.parseAsString(timingDetail.modifiedAtToTimeZone) || "-";

          // Start Location

          var steartZipCode       = ValueFormat.parseAsString(deviceField.zipCode) || "-";
          var steartState         = ValueFormat.parseAsString(deviceField.state) || "-";
          var steartCity          = ValueFormat.parseAsString(deviceField.city) || "-";
          var steartLocationName  = ValueFormat.parseAsString(locationDetail.locationName) || "-";
          var startLatitude       = ValueFormat.parseAsString(locationDetail.latitude) || "-";
          var startLongitude      = ValueFormat.parseAsString(locationDetail.longitude) || "-";

          // End Location

          var endZipCode       = ValueFormat.parseAsString(lastDeviceField.zipCode) || "-";
          var endState         = ValueFormat.parseAsString(lastDeviceField.state) || "-";
          var endCity          = ValueFormat.parseAsString(lastDeviceField.city) || "-";
          var endLocationName  = ValueFormat.parseAsString(locationDetail.lastLocationName) || "-";
          var lastLatitude       = ValueFormat.parseAsString(locationDetail.lastLatitude) || "-";
          var lastLongitude      = ValueFormat.parseAsString(locationDetail.lastLongitude) || "-";

          // Data Count

          var startDataCount        = ValueFormat.parseAsString(dataCountDetail.startDataCount) || "-";
          var endDataCount          = ValueFormat.parseAsString(dataCountDetail.endDataCount) || "-";
          var distanceDataCount     = ValueFormat.parseAsString(dataCountDetail.distanceDataCount) || "-";
          var errorDataCount        = ValueFormat.parseAsString(dataCountDetail.errorDataCount) || "-";
          var alertDataCount        = ValueFormat.parseAsString(dataCountDetail.alertDataCount) || "-";
          var stressStrainDataCount = ValueFormat.parseAsString(dataCountDetail.stressStrainDataCount) || "-";
          var manualDataCount       = ValueFormat.parseAsString(dataCountDetail.manualDataCount) || "-";

          // Alert Count

          var lowAlertCount       = ValueFormat.parseAsString(alertSummaryDetail.lowAlertCount) || "-";
          var mediumAlertCount    = ValueFormat.parseAsString(alertSummaryDetail.mediumAlertCount) || "-";
          var highAlertCount      = ValueFormat.parseAsString(alertSummaryDetail.highAlertCount) || "-";

          // Risk Slot Detail

          var negativeCount      = ValueFormat.parseAsString(riskSlotDetail.negativeCount) || "-";
          var zeroCount          = ValueFormat.parseAsString(riskSlotDetail.zeroCount) || "-";
          var slot1count         = ValueFormat.parseAsString(riskSlotDetail.slot1count) || "-";
          var slot2count         = ValueFormat.parseAsString(riskSlotDetail.slot2count) || "-";
          var slot3count         = ValueFormat.parseAsString(riskSlotDetail.slot3count) || "-";
          var slot4count         = ValueFormat.parseAsString(riskSlotDetail.slot4count) || "-";
          var slot5count         = ValueFormat.parseAsString(riskSlotDetail.slot5count) || "-";
          var slot6count         = ValueFormat.parseAsString(riskSlotDetail.slot6count) || "-";
          var slot7count         = ValueFormat.parseAsString(riskSlotDetail.slot7count) || "-";
          var slot7DistanceInKm  = ValueFormat.parseAsString(riskSlotDetail.slot7DistanceInKm) || "-";
          var slot7DurationInMin  = ValueFormat.parseAsString(riskSlotDetail.slot7DurationInMin) || "-";
          var slot8count         = ValueFormat.parseAsString(riskSlotDetail.slot8count) || "-";
          var slot8DistanceInKm  = ValueFormat.parseAsString(riskSlotDetail.slot8DistanceInKm) || "-";
          var slot8DurationInMin  = ValueFormat.parseAsString(riskSlotDetail.slot8DurationInMin) || "-";
          var slot9count         = ValueFormat.parseAsString(riskSlotDetail.slot9count) || "-";
          var slot9DistanceInKm  = ValueFormat.parseAsString(riskSlotDetail.slot9DistanceInKm) || "-";
          var slot9DurationInMin  = ValueFormat.parseAsString(riskSlotDetail.slot9DurationInMin) || "-";
          var slot10count         = ValueFormat.parseAsString(riskSlotDetail.slot10count) || "-";
          var slot10DistanceInKm  = ValueFormat.parseAsString(riskSlotDetail.slot10DistanceInKm) || "-";
          var slot10DurationInMin  = ValueFormat.parseAsString(riskSlotDetail.slot10DurationInMin) || "-";

          // Acc

          var accelerationLowCount    = ValueFormat.parseAsString(stressStrainDataDetail.accelerationLowCount) ||"-";
          var accelerationMediumCount = ValueFormat.parseAsString(stressStrainDataDetail.accelerationMediumCount) || "-";
          var accelerationHighCount   = ValueFormat.parseAsString(stressStrainDataDetail.accelerationHighCount) || "-";

          // Brak

          var brakingLowCount    = ValueFormat.parseAsString(stressStrainDataDetail.brakingLowCount) || "-";
          var brakingMediumCount = ValueFormat.parseAsString(stressStrainDataDetail.brakingMediumCount) || "-";
          var brakingHighCount   = ValueFormat.parseAsString(stressStrainDataDetail.brakingHighCount) || "-";

          // Corn

          var corneringLowCount    = ValueFormat.parseAsString(stressStrainDataDetail.corneringLowCount) || "-";
          var corneringMediumCount = ValueFormat.parseAsString(stressStrainDataDetail.corneringMediumCount) || "-";
          var corneringHighCount   = ValueFormat.parseAsString(stressStrainDataDetail.corneringHighCount) || "-";

          // Over Speed

          var overSpeedCount         = ValueFormat.parseAsString(manualDataDetail.overSpeedCount) || "-";
          var overSpeedDistanceInKm  = ValueFormat.parseAsString(manualDataDetail.overSpeedDistanceInKm) || "-";
          var overSpeedDurationInMin = ValueFormat.parseAsString(manualDataDetail.overSpeedDurationInMin) || "-";

          // Mobile Call

          var mobileCallCount         = ValueFormat.parseAsString(manualDataDetail.mobileCallCount) || "-";
          var mobileCallDistanceInKm  = ValueFormat.parseAsString(manualDataDetail.mobileCallDistanceInKm) || "-";
          var mobileCallDurationInMin = ValueFormat.parseAsString(manualDataDetail.mobileCallDurationInMin) || "-";

          // Mobile Screen

          var mobileScreenCount         = ValueFormat.parseAsString(manualDataDetail.mobileScreenCount) || "-";
          var mobileScreenDistanceInKm  = ValueFormat.parseAsString(manualDataDetail.mobileScreenDistanceInKm) || "-";
          var mobileScreenDurationInMin = ValueFormat.parseAsString(manualDataDetail.mobileScreenDurationInMin) || "-";

          // Driving State

          var unknownCount         = ValueFormat.parseAsString(drivingStateDetail.unknownCount) || "-";
          var unknownDistanceInKm  = ValueFormat.parseAsString(drivingStateDetail.unknownDistanceInKm) || "-";
          var unknownDurationInMin = ValueFormat.parseAsString(drivingStateDetail.unknownDurationInMin) || "-";
          var normalCount          = ValueFormat.parseAsString(drivingStateDetail.normalCount) || "-";
          var normalDistanceInKm   = ValueFormat.parseAsString(drivingStateDetail.normalDistanceInKm) || "-";
          var normalDurationInMin  = ValueFormat.parseAsString(drivingStateDetail.normalDurationInMin) || "-";
          var tiredCount           = ValueFormat.parseAsString(drivingStateDetail.tiredCount) || "-";
          var tiredDistanceInKm    = ValueFormat.parseAsString(drivingStateDetail.tiredDistanceInKm) || "-";
          var tiredDurationInMin   = ValueFormat.parseAsString(drivingStateDetail.tiredDurationInMin) || "-";
          var angryCount           = ValueFormat.parseAsString(drivingStateDetail.angryCount) || "-";
          var angryDistanceInKm    = ValueFormat.parseAsString(drivingStateDetail.angryDistanceInKm) || "-";
          var angryDurationInMin   = ValueFormat.parseAsString(drivingStateDetail.angryDurationInMin) || "-";

          // Driving Style

          var unknownCountState         = ValueFormat.parseAsString(drivingStyleDetail.unknownCount) || "-";
          var unknownDistanceInKmState  = ValueFormat.parseAsString(drivingStyleDetail.unknownDistanceInKm) || "-";
          var unknownDurationInMinState = ValueFormat.parseAsString(drivingStyleDetail.unknownDurationInMin) || "-";

          var normalCountState         = ValueFormat.parseAsString(drivingStyleDetail.normalCount) || "-";
          var normalDistanceInKmState  = ValueFormat.parseAsString(drivingStyleDetail.normalDistanceInKm) || "-";
          var normalDurationInMinState = ValueFormat.parseAsString(drivingStyleDetail.normalDurationInMin) || "-";

          var sportiveCountState         = ValueFormat.parseAsString(drivingStyleDetail.sportiveCount) || "-";
          var sportiveDistanceInKmState  = ValueFormat.parseAsString(drivingStyleDetail.sportiveDistanceInKm) || "-";
          var sportiveDurationInMinState = ValueFormat.parseAsString(drivingStyleDetail.sportiveDurationInMin) || "-";

          var ecoCountState         = ValueFormat.parseAsString(drivingStyleDetail.ecoCount) || "-";
          var ecoDistanceInKmState  = ValueFormat.parseAsString(drivingStyleDetail.ecoDistanceInKm) || "-";
          var ecoDurationInMinState = ValueFormat.parseAsString(drivingStyleDetail.ecoDurationInMin) || "-";

          var chaoticCountState         = ValueFormat.parseAsString(drivingStyleDetail.chaoticCount) || "-";
          var chaoticDistanceInKmState  = ValueFormat.parseAsString(drivingStyleDetail.chaoticDistanceInKm) || "-";
          var chaoticDurationInMinState = ValueFormat.parseAsString(drivingStyleDetail.chaoticDurationInMin) || "-";

          var aggressiveCountState         = ValueFormat.parseAsString(drivingStyleDetail.aggressiveCount) || "-";
          var aggressiveDistanceInKmState  = ValueFormat.parseAsString(drivingStyleDetail.aggressiveDistanceInKm) || "-";
          var aggressiveDurationInMinState = ValueFormat.parseAsString(drivingStyleDetail.aggressiveDurationInMin) || "-";

          // Alert Count (Individual)

          var accidentCount                   = ValueFormat.parseAsString(alertCountDetail.accidentCount) ||"-";
          var animalCrossingCount             = ValueFormat.parseAsString(alertCountDetail.animalCrossingCount) || "-";
          var cautionCount                    = ValueFormat.parseAsString(alertCountDetail.cautionCount) || "-";
          var congestionCount                 = ValueFormat.parseAsString(alertCountDetail.congestionCount) || "-";
          var curveCount                      = ValueFormat.parseAsString(alertCountDetail.curveCount) || "-";
          var hillCount                       = ValueFormat.parseAsString(alertCountDetail.hillCount) || "-";
          var hillDownwardsCount              = ValueFormat.parseAsString(alertCountDetail.hillDownwardsCount) || "-";
          var hillUpwardsCount                = ValueFormat.parseAsString(alertCountDetail.hillUpwardsCount) || "-";
          var icyConditionsCount              = ValueFormat.parseAsString(alertCountDetail.icyConditionsCount) || "-";
          var intersectionCount               = ValueFormat.parseAsString(alertCountDetail.intersectionCount) || "-";
          var laneMergeCount                  = ValueFormat.parseAsString(alertCountDetail.laneMergeCount) || "-";
          var lowGearAreaCount                = ValueFormat.parseAsString(alertCountDetail.lowGearAreaCount) || "-";
          var narrowRoadCount                 = ValueFormat.parseAsString(alertCountDetail.narrowRoadCount) || "-";
          var noOvertakingCount               = ValueFormat.parseAsString(alertCountDetail.noOvertakingCount) || "-";
          var noOvertakingTrucksCount         = ValueFormat.parseAsString(alertCountDetail.noOvertakingTrucksCount) || "-";
          var pedestrianCrossingCount         = ValueFormat.parseAsString(alertCountDetail.pedestrianCrossingCount) || "-";
          var priorityCount                   = ValueFormat.parseAsString(alertCountDetail.priorityCount) || "-";
          var priorityToOncomingTrafficCount  = ValueFormat.parseAsString(alertCountDetail.priorityToOncomingTrafficCount) || "-";
          var railwayCrossingCount            = ValueFormat.parseAsString(alertCountDetail.railwayCrossingCount) || "-";
          var riskOfGroundingCount            = ValueFormat.parseAsString(alertCountDetail.riskOfGroundingCount) || "-";
          var roundAboutCount                 = ValueFormat.parseAsString(alertCountDetail.roundAboutCount) || "-";
          var schoolZoneCount                 = ValueFormat.parseAsString(alertCountDetail.schoolZoneCount) || "-";
          var slipperyRoadsCount              = ValueFormat.parseAsString(alertCountDetail.slipperyRoadsCount) || "-";
          var stopSignCount                   = ValueFormat.parseAsString(alertCountDetail.stopSignCount) || "-";
          var trafficLightCount               = ValueFormat.parseAsString(alertCountDetail.trafficLightCount) || "-";
          var tramwayCrossingCount            = ValueFormat.parseAsString(alertCountDetail.tramwayCrossingCount) || "-";
          var windCount                       = ValueFormat.parseAsString(alertCountDetail.windCount) || "-";
          var windingRoadCount                = ValueFormat.parseAsString(alertCountDetail.windingRoadCount) || "-";
          var yieldCount                      = ValueFormat.parseAsString(alertCountDetail.yieldCount) || "-";

          let statusList = "";

          if (status == "INITIATED" || status == "REGISTERED") {
            statusList = "Unregistered";
          } else if (status == "ACTIVE" && overallRideCount <= 0) {
            statusList = "Inactive";
          } else if (status == "ACTIVE" && overallRideCount > 0) {
            statusList = "Active";
          } else if (status == "DEACTIVATED") {
            statusList = "Deactivated";
          } else {
            statusList = "-";
          }


          var rideGetDataList = {

            userId:userId,
            rideId:rideId,

            firstName:firstName,
            username:username,
            deviceType:deviceType,
            vehicleType:vehicleType,
            usernameType:usernameType,
            createdAtToTimeZone:createdAtToTimeZone,
            active:active,
            status:statusList,

            rideName:rideName,
            distanceInKm:distanceInKm,
            durationInMin:durationInMin,
            dayPercentage:dayPercentage,
            nightPercentage:nightPercentage,

            category:category,
            deviceMode:deviceMode,
            rideOtherDetailStatus:rideOtherDetailStatus,
            insertMode:insertMode,
            timeZoneCode:timeZoneCode,
            batteryLevelStart:batteryLevelStart,
            batteryLevelEnd:batteryLevelEnd,
            deviceRawFileName:deviceRawFileName,
            deviceRawFileStatus:deviceRawFileStatus,
            deviceSummaryStatus:deviceSummaryStatus,
            deviceSummaryModifiedAtToTimeZone:deviceSummaryModifiedAtToTimeZone,
            subType:subType,
            eventAM:eventAM,
            attrDeviceType:attrDeviceType,

            calDrivingScore:calDrivingScore,
            calRiskScore:calRiskScore,
            calBehaviourScore:calBehaviourScore,
            crashProbabilityScore:crashProbabilityScore,
            calDrivingCategory:calDrivingCategory,

            anticipationScore:anticipationScore,
            selfConfidenceScore:selfConfidenceScore,
            drivingSkillScore:drivingSkillScore,

            calDrivingStyleScore:calDrivingStyleScore,
            calDrivingStateScore:calDrivingStateScore,
            calOverspeedScore:calOverspeedScore,
            calAccBrakCorScore:calAccBrakCorScore,
            calMobileCallScore:calMobileCallScore,
            calMobileScreenScore:calMobileScreenScore,

            startDateTimeToTimeZone:startDateTimeToTimeZone,
            endDateTimeToTimeZone:endDateTimeToTimeZone,
            timingsCreatedAtToTimeZone:timingsCreatedAtToTimeZone,
            insertedAtToTimeZone:insertedAtToTimeZone,
            modifiedAtToTimeZone:modifiedAtToTimeZone,

            steartZipCode:steartZipCode,
            steartState:steartState,
            steartCity:steartCity,
            steartLocationName:steartLocationName,
            startLatitude:startLatitude,
            startLongitude:startLongitude,

            endZipCode:endZipCode,
            endState:endState,
            endCity:endCity,
            endLocationName:endLocationName,
            lastLatitude:lastLatitude,
            lastLongitude:lastLongitude,

            startDataCount:startDataCount,
            endDataCount:endDataCount,
            distanceDataCount:distanceDataCount,
            errorDataCount:errorDataCount,
            alertDataCount:alertDataCount,
            stressStrainDataCount:stressStrainDataCount,
            manualDataCount:manualDataCount,

            lowAlertCount:lowAlertCount,
            mediumAlertCount:mediumAlertCount,
            highAlertCount:highAlertCount,

            negativeCount:negativeCount,
            zeroCount:zeroCount,
            slot1count:slot1count,
            slot2count:slot2count,
            slot3count:slot3count,
            slot4count:slot4count,
            slot5count:slot5count,
            slot6count:slot6count,
            slot7count:slot7count,
            slot7DistanceInKm:slot7DistanceInKm,
            slot7DurationInMin:slot7DurationInMin,
            slot8count:slot8count,
            slot8DistanceInKm:slot8DistanceInKm,
            slot8DurationInMin:slot8DurationInMin,
            slot9count:slot9count,
            slot9DistanceInKm:slot9DistanceInKm,
            slot9DurationInMin:slot9DurationInMin,
            slot10count:slot10count,
            slot10DistanceInKm:slot10DistanceInKm,
            slot10DurationInMin:slot10DurationInMin,

            accelerationLowCount:accelerationLowCount,
            accelerationMediumCount:accelerationMediumCount,
            accelerationHighCount:accelerationHighCount,

            brakingLowCount:brakingLowCount,
            brakingMediumCount:brakingMediumCount,
            brakingHighCount:brakingHighCount,

            corneringLowCount:corneringLowCount,
            corneringMediumCount:corneringMediumCount,
            corneringHighCount:corneringHighCount,
            
            overSpeedCount:overSpeedCount,
            overSpeedDistanceInKm:overSpeedDistanceInKm,
            overSpeedDurationInMin:overSpeedDurationInMin,

            mobileCallCount:mobileCallCount,
            mobileCallDistanceInKm:mobileCallDistanceInKm,
            mobileCallDurationInMin:mobileCallDurationInMin,

            mobileScreenCount:mobileScreenCount,
            mobileScreenDistanceInKm:mobileScreenDistanceInKm,
            mobileScreenDurationInMin:mobileScreenDurationInMin,

            unknownCount:unknownCount,
            unknownDistanceInKm:unknownDistanceInKm,
            unknownDurationInMin:unknownDurationInMin,
            normalCount:normalCount,
            normalDistanceInKm:normalDistanceInKm,
            normalDurationInMin:normalDurationInMin,
            tiredCount:tiredCount,
            tiredDistanceInKm:tiredDistanceInKm,
            tiredDurationInMin:tiredDurationInMin,
            angryCount:angryCount,
            angryDistanceInKm:angryDistanceInKm,
            angryDurationInMin:angryDurationInMin,

            unknownCountState:unknownCountState,
            unknownDistanceInKmState:unknownDistanceInKmState,
            unknownDurationInMinState:unknownDurationInMinState,
            normalCountState:normalCountState,
            normalDistanceInKmState:normalDistanceInKmState,
            normalDurationInMinState:normalDurationInMinState,
            sportiveCountState:sportiveCountState,
            sportiveDistanceInKmState:sportiveDistanceInKmState,
            sportiveDurationInMinState:sportiveDurationInMinState,
            ecoCountState:ecoCountState,
            ecoDistanceInKmState:ecoDistanceInKmState,
            ecoDurationInMinState:ecoDurationInMinState,
            chaoticCountState:chaoticCountState,
            chaoticDistanceInKmState:chaoticDistanceInKmState,
            chaoticDurationInMinState:chaoticDurationInMinState,
            aggressiveCountState:aggressiveCountState,
            aggressiveDistanceInKmState:aggressiveDistanceInKmState,
            aggressiveDurationInMinState:aggressiveDurationInMinState,

            accidentCount:accidentCount,
            animalCrossingCount:animalCrossingCount,
            cautionCount:cautionCount,
            congestionCount:congestionCount,
            curveCount:curveCount,
            hillCount:hillCount,
            hillDownwardsCount:hillDownwardsCount,
            hillUpwardsCount:hillUpwardsCount,
            icyConditionsCount:icyConditionsCount,
            intersectionCount:intersectionCount,
            laneMergeCount:laneMergeCount,
            lowGearAreaCount:lowGearAreaCount,
            narrowRoadCount:narrowRoadCount,
            noOvertakingCount:noOvertakingCount,
            noOvertakingTrucksCount:noOvertakingTrucksCount,
            pedestrianCrossingCount:pedestrianCrossingCount,
            priorityCount:priorityCount,
            priorityToOncomingTrafficCount:priorityToOncomingTrafficCount,
            railwayCrossingCount:railwayCrossingCount,
            riskOfGroundingCount:riskOfGroundingCount,
            roundAboutCount:roundAboutCount,
            schoolZoneCount:schoolZoneCount,
            slipperyRoadsCount:slipperyRoadsCount,
            stopSignCount:stopSignCount,
            trafficLightCount:trafficLightCount,
            tramwayCrossingCount:tramwayCrossingCount,
            windCount:windCount,
            windingRoadCount:windingRoadCount,
            yieldCount:yieldCount

          }
          
          updatedRideData.push(rideGetDataList)
         
        })
        
        setSummaryDataList(updatedRideData);
        

        

        
       
        
        
      } else {   
        setDataLength(0);
        setShowLoader(false)
        // console.log("Status failed");
        var code = resultJSON.error.code;

        if (code == "E1206") {
          setShowLoader(false);
          // setDataDisplay("failed");
        }
      }
      setShowLoader(false)
      if (indexStartOfSet == 0) {
        setPrevShow(false);
      } else if (indexStartOfSet > 0) {
        setPrevShow(true);
      }
    } catch (errMes) {}
  };

 
  // useEffect(() => {
  //     setPageNumber(1);
  //     if (indexStart == 0) setCount((prev) => prev + 1);
  //     else setIndexStart(0);
  // }, [filter,exceptionFilter,deviceFilter,startDateTime,endDateTime,selectedOption]);

//   useEffect(() => {
//     if (count > 0) getAppExceptionReport(indexStart);
// }, [indexStart,count]);

  useEffect(() => {
    getRideListSA(indexStart,"");
  }, [indexStart,selectedValue,exceptionFilter,exceptionFilter2,deviceModeFilter,filters.startDateTime,filters.endDateTime,deviceTypefilter.durationFilter,filters.durationFilter]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  function capitalizeLetter  (category){
    if(category){
    return category
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
    }else{
      return "-"
    }
  } 
  
  const clearSearchInput = () => {
       
    setSearchText("");      
    getRideListSA(0,"CLEAR");     
    setPageNumber(1); 
      
  }

  const clearSearchInputRide = () => {
       
    setSearchRideNumber("");      
    getRideListSA(0,"CLEAR_RIDE");     
    setPageNumber(1); 
      
  }


  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        {/* {loginType == "SUPER_ADMIN" ? (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Welcome, You are viewing{" "}
            <span
              style={{
                fontSize: "13px",
                fontWeight: 800,
              }}
            >
              {userFirstName}'s
            </span>{" "}
            App Exception
          </span>
        ) : ( */}
            <Row className="my-2 py-2 d-flex justify-content-start align-items-center" >
            <Col xs={3} className="d-flex justify-content-start align-items-center text-black" style={{ height:"0px"}}>
              <h5 className="mb-0" style={{textWrap:"nowrap",}}>User Rides</h5>
            </Col>
            <Col xs={9} className="d-flex justify-content-end align-items-center text-black" style={{gap:10,}}>
              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center", }}>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == ""
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => {
                    setIndexStart(0);
                    setPageNumber(1);
                    setExceptionFilter("")
                  }}
                 
                >
                  All
                 
                </button>

                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "VALID"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => {
                    setIndexStart(0);
                    setPageNumber(1);
                    setExceptionFilter("VALID")
                  }}
                 
                >
                  Valid
                  
                </button>

                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "INVALID"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => {
                    setIndexStart(0);
                    setPageNumber(1);
                    setExceptionFilter("INVALID")
                  }}
                 
                >
                  Invalid
                 
                </button>

                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "ABNORMAL"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => {
                    setIndexStart(0);
                    setPageNumber(1);
                    setExceptionFilter("ABNORMAL")
                  }}
                  
                >
                  Abnormal
                 
                </button>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    exceptionFilter == "DELETED"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => {
                    setIndexStart(0);
                    setPageNumber(1);
                    setExceptionFilter("DELETED")
                  }}
                 
                >
                  Deleted
                  
                </button>
              </div>
              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end" style={{ textAlign: "center", }}>
                <div className="mt-0 mb-0 mr-0 ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center" }}>
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == ""
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => {
                      setIndexStart(0);
                      setPageNumber(1);
                      setExceptionFilter2("")
                    }}
                   
                  >
                    All
                   
                  </button>          
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "DRIVER"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => {
                      setIndexStart(0);
                      setPageNumber(1);
                      setExceptionFilter2("DRIVER")
                    }}
                   
                  >
                    Driver
                    
                  </button>
                  <button style={{ fontWeight: 700 }}
                    type="button"
                    className={
                      exceptionFilter2 == "PASSENGER"
                        ? "btn btnColorStylesNew btn-sm m-1"
                        : "btn btn-soft-secondary btn-sm m-1"
                    }
                    onClick={() => {
                      setIndexStart(0);
                      setPageNumber(1);
                      setExceptionFilter2("PASSENGER")
                    }}
                   
                  >
                    Passenger
                    
                  </button>             
                         
                  
                </div>
              </div>

              <div className="pb-0 mt-0 mb-0 d-flex justify-content-end" style={{ textAlign: "center", }}>
                <div className="mt-0 mb-0 mr-0 ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center" }}>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    deviceModeFilter == ""
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => {
                    setIndexStart(0);
                    setPageNumber(1);
                    setDeviceModeFilter("")
                  }}
                  
                >
                  All
                 
                </button>              

                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    deviceModeFilter == "PERSONAL"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => {
                    setIndexStart(0);
                    setPageNumber(1);
                    setDeviceModeFilter("PERSONAL")
                  }}
                 
                >
                  Personal
                 
                </button>
                <button style={{ fontWeight: 700 }}
                  type="button"
                  className={
                    deviceModeFilter == "WORK"
                      ? "btn btnColorStylesNew btn-sm m-1"
                      : "btn btn-soft-secondary btn-sm m-1"
                  }
                  onClick={() => {
                    setIndexStart(0);
                    setPageNumber(1);
                    setDeviceModeFilter("WORK");
                  }}
                 
                >
                  Work
                 
                </button>       
                         
                  
                </div>
              </div>

              <div className="pb-0 mb-0 mt-0 d-flex justify-content-end">
                <div className="ms-auto pe-0 ">
                  <div style={{ float: "right",  }}>
                 
                    <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "ALL" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => handleChange("ALL")}
                      >
                        All                           
                      </button>
                     
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_DAY" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => handleChange("CURRENT_DAY")}
                      >
                        CD                           
                      </button>
                      
                      {" "}
                     
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_WEEK" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => handleChange("CURRENT_WEEK")}
                      >
                        CW                           
                      </button>
                     
                      {" "}
                     
                      <button
                        type="button"                           
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_MONTH" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => handleChange("CURRENT_MONTH")}
                      >
                        CM
                        
                      </button>
                     {" "}
                     
                      <button
                        type="button"                            
                        style={{ fontWeight: 700 }}
                        className={
                          filters.durationFilter == "CURRENT_YEAR" &&
                          !modalIsOpen
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => handleChange("CURRENT_YEAR")}
                      >
                        CY                            
                      </button>
                      {" "}
                     
                      <button
                        type="button"
                        className={
                          filters.durationFilter == "CUSTOM_DURATION" ||
                          modalIsOpen
                            ? "btn btnColorStylesNew btn-sm m-1"
                            : "btn btnColorStylesSecondaryNew btn-sm m-1"
                        }
                        onClick={() => {
                          setIndexStart(0);
                          setPageNumber(1);
                          setIsOpen(true);
                          setCount(1);
                         
                        }}
                      
                      >
                        {filter == "CUSTOM_DURATION" || modalIsOpen ? (
                          <img
                            src={filterClockIcon}
                            alt="clock"
                            style={{
                              height: "18px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          />
                        ) : (
                          <img
                            src={filterClockIconDe}
                            alt="clock"
                            style={{
                              height: "18px",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          />
                        )}                           
                      </button>{" "}
                    
                  </div>
                </div>
                {/* <ExportScreenPdf fileName={userFirstName + "'s" + " Dashboard"} /> */}
              </div>
            </Col>
          </Row>
          {/* )}  */}

           

              <Row style={{marginTop:"-20px", }}>
                <Col xl={12} md={12} sm={12}>
                  <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",}}>
                      <div className="my-3" style={{ display: "flex", justifyContent: "center", flexDirection: "row", height: "40px",marginTop: "-50px", }}>
                        <div 
                       style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width:"250px",
                        position: "relative", // Add relative positioning to the wrapper
                      }}
                        className="me-3"
                         >
              <input 
              // type="search" 
              style={{ border: " 1px solid #EEEBEB", width: "100%", height: "80%", }}
                value={searchText}
                onKeyPress={handleKeyPress}
                onChange={(e) => { setSearchText(e.target.value); }}
                placeholder="Search User Detail"
              />
              {searchText &&
  <span
    style={{
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)", 
      cursor: "pointer",
      color: "#888", 
    }}
    onClick={clearSearchInput}
  >
    X
  </span>}
            </div>

            <div 
           style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width:"250px",
            position: "relative", // Add relative positioning to the wrapper
          }}
             >
              <input 
              // type="search" 
              style={{ border: " 1px solid #EEEBEB", width: "100%", height: "80%", }}
                value={searchRideNumber}
                onKeyPress={handleKeyPressRide}
                onChange={(e) => { setSearchRideNumber(e.target.value); }}
                placeholder="Search Ride Detail"
              />
               {searchRideNumber &&
  <span
    style={{
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)", 
      cursor: "pointer",
      color: "#888", 
    }}
    onClick={clearSearchInputRide}
  >
    X
  </span>}
            </div>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}  >
                          <button ref={inputRef}
                            style={{ marginLeft: "5px", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column", }}
                            className="btn btnColorStylesNew me-3"
                            type="button"
                            onClick={handleSearch}
                          >
                            <i className="bx bx-search-alt align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", gap:10}}>
                      
                        <div className="mt-0 mb-0 mr-0 ms-auto pe-2 border-end border-secondary" style={{ textAlign: "center",  }}>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceTypefilter.durationFilter == ""
                                ? "btn btn btnColorStylesNew btn-sm m-1"
                                : "btn btn-soft-secondary btn-sm m-1"
                            }
                            onClick={() => {
                              setIndexStart(0);
                              setPageNumber(1);
                              setDeviceTypefilter({
                                ...deviceTypefilter,
                                durationFilter:"",
                              });
                            }}
                            
                          >
                            All
                           
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceTypefilter.durationFilter == "ANDROID"
                                ? "btn btnColorStylesNew btn-sm m-1"
                                : "btn btn-soft-secondary btn-sm m-1"
                            }                           

                            onClick={() => {
                              setIndexStart(0);
                              setPageNumber(1);
                              setDeviceTypefilter({
                                ...deviceTypefilter,
                                durationFilter: "ANDROID",
                              });
                            }}
                           
                          >
                            Android
                            
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceTypefilter.durationFilter == "IOS"
                                ? "btn btnColorStylesNew btn-sm m-1"
                                : "btn btn-soft-secondary btn-sm m-1"
                            }                           

                            onClick={() => {
                              setIndexStart(0);
                              setPageNumber(1);
                              setDeviceTypefilter({
                                ...deviceTypefilter,
                                durationFilter: "IOS",
                              });
                            }}
                          
                          >
                            iOS
                           
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceTypefilter.durationFilter == "IOS_SDK"
                                ? "btn btnColorStylesNew btn-sm m-1"
                                : "btn btn-soft-secondary btn-sm m-1"
                            }                           
                            
                            onClick={() => {
                              setIndexStart(0);
                              setPageNumber(1);
                              setDeviceTypefilter({
                                ...deviceTypefilter,
                                durationFilter: "IOS_SDK",
                              });
                            }}
                          >
                            iOS SDK
                           
                          </button>
                          <button style={{ fontWeight: 700 }}
                            type="button"
                            className={
                              deviceTypefilter.durationFilter == "ANDROID_SDK"
                                ? "btn btnColorStylesNew btn-sm m-1"
                                : "btn btn-soft-secondary btn-sm m-1"
                            }
                           
                            onClick={() => {
                              setIndexStart(0);
                              setPageNumber(1);
                              setDeviceTypefilter({
                                ...deviceTypefilter,
                                durationFilter: "ANDROID_SDK",
                              });
                            }}
                          >
                            Android SDK
                            
                          </button>
                        </div>
                        <div className="my-0" style={{ height: "0px", display: "flex", alignItems:"center", marginTop: "0x",  }}>
                          <div className=" mr-0 ms-auto pe-0  border-secondary" >
                            <div style={{ float: "right" }}>
                            {["ALL_USER","REGISTERD_USER", "DEACTIVATED_USER", "DELETED_USER"].map((type) => (
                              <button
                                key={type}
                                type="button"
                                style={{
                                  fontWeight: 700,
                                  // backgroundColor: selectedValue === type ? "btn btnColorStylesNew btn-sm" : "btn btn-soft-secondary btn-sm", // Highlight selected
                                  // color: selectedValue === type ? "#fff" : "#000",
                                  marginRight:"5px"
                                }}
                                className={`btn btn-sm ${
                                  selectedValue === `${type}_LIST` ? "btn btnColorStylesNew btn-sm m-1" : "btn btn-soft-secondary btn-sm m-1"
                                }`}
                                id={type}
                                onClick={() => handleUserClick(type)} // Pass type to handler
                              >
                                {capitalizeLetter(type)} {/* Format button label */}
                              </button>
                            ))}
                            </div>
                          </div>
                        </div>
                      </div>
                 
                  </div>

                  <div className="mt-0" style={{ overflowX: "auto",border:"1px solid #DFDEDE"}} >
                    <Table className="table table-bordered mb-0" style={{ borderCollapse: "collapse" }}>
                      <thead className="cardHeader">
                        <tr>
                          <th className="cardHeader" style={{ fontSize: "13px", fontWeight: 600, padding: "14px", position:!(dataLength === 0) && !(dataLength === null) && "sticky", left:!(dataLength === 0) && !(dataLength === null) && -2, zIndex:!(dataLength === 0) && !(dataLength === null) && 2, background:"white" }} >
                            User Details                            
                          </th>
                          <th className="cardHeader"     style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              padding: "14px",
                              textAlign: "center",
                              position:!(dataLength === 0) && !(dataLength === null) && "sticky",
                              left:!(dataLength === 0) && !(dataLength === null) && 120,
                              zIndex:!(dataLength === 0) && !(dataLength === null) && 2,
                              background:"white",
                            }} >
                            Ride Details
                          </th>
                          <th colSpan={3} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }}  >
                            Attributes
                          </th>
                         
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                            Score Details
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Risk Data
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Behaviour Data
                          </th> 
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Timings
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap" }} >
                          Start Location
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          End Location
                          </th> 
                          <th colSpan={2} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Data Count
                          </th>      
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Alert Count
                          </th>         
                          <th colSpan={3} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Risk Slot Detail
                          </th>  
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                          Acc
                          </th>  
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Brak
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Corn
                          </th> 
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Over Speed
                          </th>         
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Mobile Call
                          </th>   
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center",whiteSpace:"nowrap"}} >
                           Mobile Screen
                          </th>    
                          <th style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Driving State
                          </th>     
                          <th colSpan={2} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Driving Style
                          </th>  
                          <th colSpan={5} style={{ fontSize: "13px", fontWeight: 600, padding: "14px", textAlign: "center" }} >
                           Alert Count (Individual)
                          </th>                       
                        </tr>
                      </thead>

                      <tbody>
                        {
                        dataLength > 0 ? 
                        (
                          (summaryDataList || []).map((apiData, index) => (
                            <tr 
                            key={index}
                            >
                              <td style={{ fontSize: "13px", verticalAlign: "middle", position:!(dataLength === 0) && !(dataLength === null) && "sticky", left:!(dataLength === 0) && !(dataLength === null) && -2, zIndex:!(dataLength === 0) && !(dataLength === null) && 2, backgroundColor: "white", }} >
                                <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={UserNameIcon} alt="icons2" style={{ height: "20px", marginLeft: "-2px", }} />
                                  </div>                          
                                  <div className="second-div-flex" >     
                                      <span className="span-tag-name" style={{whiteSpace:"nowrap"}} >
                                        {apiData.firstName} 
                                        </span>
                                  </div>
                                  </div>
                                  
                                  <div className="div-flex" style={{marginTop:"10px"}} >
                                  <div>
                                    <p className="p-tag-username">
                                      {apiData.username} 
                                      </p>
                                  </div>
                                  <div style={{marginLeft:"4px"}}>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    >
                                    {apiData.status}
                                    </button>
                                  </div>
                                  </div>
                                

                                <div  className="div-flex-margin" >
                                    <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        {apiData.deviceType == "ANDROID" ? (
                                          <img src={andIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} /> 
                                        ) : apiData.deviceType == "IOS" ? (
                                          <img src={iosIconNew} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                        ) : (
                                          <img src={noDeviceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                        )}
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {(apiData.deviceType == "ANDROID") || (apiData.deviceType == "IOS" ) ? "Mobile" : (apiData.deviceType == "ANDROID_SDK") || (apiData.deviceType == "IOS_SDK" )  ?  "SDK" : "Other"}
                                      </div>
                                    </div>     
                                    <div className="div-spacing" > | </div>
                                    <div style={{ display: "flex", marginLeft: "3px", }}>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                        <img src={FTwheelerIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginLeft: "4px", color: "#000000", fontWeight: 400, fontSize: "11px", }} >
                                        {apiData.vehicleType === "FOUR_WHEELER" 
                                          ? "4W" 
                                          : apiData.vehicleType === "TWO_WHEELER" 
                                          ? "2W" 
                                          : "-"}
                                      </div>
                                    </div>
                                </div>

                                <div>
                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActType} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Type : </p>
                                    <p className="p-tag-text-left" >{capitalizeLetter(apiData.active)}</p>
                                  </div>

                                  <div className="div-flex-reg-act">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ActiveDate} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Act Date : </p>
                                    <p className="p-tag-text-left" >
                                      {UtilDateTime.formatDateInt(apiData.createdAtToTimeZone)} | {formatTime(apiData.createdAtToTimeZone)} {apiData.createdAtToTimeZone.split(" ")[2]}
                                      </p>
                                  </div>
                                </div>                    
                              </td>

                              <td  style={{
                    fontSize: "13px",
                    verticalAlign: "middle",
                    textAlign: "center",
                    position:!(dataLength === 0) && !(dataLength === null) && "sticky",
                  left:!(dataLength === 0) && !(dataLength === null) && 120,
                  zIndex:!(dataLength === 0) && !(dataLength === null) && 2,
                  background:"white"
                  }}
                      >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={RideNumberIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Ride No:  </p>
                                    <Link
                                    to={`/appRideList?userId=${encodeURIComponent(
                                      Converter.encrypt(apiData.userId)
                                    )}&rideId=${encodeURIComponent(
                                      Converter.encrypt(apiData.rideId)
                                    )}`}
                                  > <p className="p-tag-text-left" style={{marginTop:"5px"}} >
                                    {apiData.rideName}
                                    </p></Link>
                                  </div>
                                  {/* <div className="div-flex-reg-detail">
                                  <div>
                                    &nbsp;
                                    <button type="button" className="btn btn-sm "
                                      style={{ backgroundColor: "#D7EC23", padding: "3px", fontSize: "8px", fontWeight: 600, }}
                                    > -
                                    {apiData.userStatus}
                                    </button>
                                  </div>
                                  </div> */}
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DistanceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.distanceInKm} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DurationIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Duration: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.durationInMin} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DayIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Day:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.dayPercentage}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={NightIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Night:</p>
                                  <p className="p-tag-text-left" >
                                    {apiData.nightPercentage}
                                  </p>
                                </div>
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={CategoryIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Category:  </p>
                                    <p className="p-tag-text-left" >
                                    {capitalizeLetter(apiData.category)}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ActivityIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Activity: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.deviceMode)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DeviceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Device: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.attrDeviceType)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StatusIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Status:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.rideOtherDetailStatus}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={EventIcon} alt="icons2" style={{ height: "25px", marginLeft: "-5px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Event:  </p>
                                    <p className="p-tag-text-left" >
                                    {capitalizeLetter(apiData.subType)}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={EventIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Event:  </p>
                                    <p className="p-tag-text-left" >
                                    {capitalizeLetter(apiData.eventAM)}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={InsertModeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Insert Mode: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.insertMode)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={TimeZoneIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Time Zone: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.timeZoneCode}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={BatteryStartIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Battery Start:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.batteryLevelStart}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={BattryEndIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Battery End:</p>
                                  <p className="p-tag-text-left" >
                                 {apiData.batteryLevelEnd}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={FileNameIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >File Name:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.deviceRawFileName}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={FileStatusIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >File Status: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.deviceRawFileName}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={SummaryStatusIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Summary Status: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.deviceSummaryStatus)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={SummaryTimeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Summary Time:</p>
                                  <p className="p-tag-text-left" >
                                 {UtilDateTime.formatDateInt(apiData.deviceSummaryModifiedAtToTimeZone)} | {formatTime(apiData.deviceSummaryModifiedAtToTimeZone)} {apiData.deviceSummaryModifiedAtToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                                

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={ScoreIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Score:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.calDrivingScore}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={GradeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Grade: </p>
                                  <p className="p-tag-text-left" >
                                  {capitalizeLetter(apiData.calDrivingCategory)}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={RiskIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Risk: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calRiskScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={BehaviorIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Behavior:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calBehaviourScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={CppIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >CPP:</p>
                                  <p className="p-tag-text-left" >
                                 {apiData.crashProbabilityScore}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AnticipationIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Anticipation:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.anticipationScore}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={SelfConfidenceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Self Confidence: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.selfConfidenceScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DrivingSkilIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Driving Skill: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.drivingSkillScore}
                                  </p>
                                </div>

                                
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={DrivingStyleIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Driving Style:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.calDrivingStyleScore}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DrivingStateIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Driving State: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calDrivingStateScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MobileUsageIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Call: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calMobileCallScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MDTotal} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Mobile Screen: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calMobileScreenScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={OverspeedIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Over Speed:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.calOverspeedScore}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AccBrakCorIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Acc/Brak/Cor:</p>
                                  <p className="p-tag-text-left" >
                                 {apiData.calAccBrakCorScore}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={TimingStartIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Start:  </p>
                                    <p className="p-tag-text-left" >
                                    {UtilDateTime.formatDateInt(apiData.startDateTimeToTimeZone)} | {formatTime(apiData.startDateTimeToTimeZone)} {apiData.startDateTimeToTimeZone.split(" ")[2]}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={TimingEndIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >End: </p>
                                  <p className="p-tag-text-left" >
                                  {UtilDateTime.formatDateInt(apiData.endDateTimeToTimeZone)} | {formatTime(apiData.endDateTimeToTimeZone)} {apiData.endDateTimeToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={CreatedIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Created: </p>
                                  <p className="p-tag-text-left" >
                                  {UtilDateTime.formatDateInt(apiData.timingsCreatedAtToTimeZone)} | {formatTime(apiData.timingsCreatedAtToTimeZone)} {apiData.timingsCreatedAtToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={InsertedIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Inserted:</p>
                                  <p className="p-tag-text-left" >
                                  {UtilDateTime.formatDateInt(apiData.insertedAtToTimeZone)} | {formatTime(apiData.insertedAtToTimeZone)} {apiData.insertedAtToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ModifiedIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Modified:</p>
                                  <p className="p-tag-text-left" >
                                  {UtilDateTime.formatDateInt(apiData.modifiedAtToTimeZone)} | {formatTime(apiData.modifiedAtToTimeZone)} {apiData.modifiedAtToTimeZone.split(" ")[2]}
                                  </p>
                                </div>

                               
                              </td>

                              <td className="td-container" >
                              <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={StartLatIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px"}}>     
                                  <p className="p-tag-text-right" >Lat:{" "} 
                                       {apiData.startLatitude}
                                        </p>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                  <p className="p-tag-text-right" >Long:{" "} 
                                        {apiData.startLongitude}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StartZipcodeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zipcode: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.steartZipCode}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={StartLocationIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Location: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.steartLocationName}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StartAddressIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Address:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.steartLocationName}, {apiData.steartCity}, {apiData.steartState} 
                                  </p>
                                </div>

                               
                               
                              </td>

                              <td className="td-container" >
                              <div className="div-flex" >
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={EndLatIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px", }} />
                                  </div>                          
                                  <div className="second-div-flex" style={{marginLeft:"-2px"}}>     
                                  <p className="p-tag-text-right" >Lat:{" "} 
                                        {apiData.lastLatitude}
                                        </p>
                                  </div>
                                  <div className="div-spacing" > | </div>
                                  <div>
                                  <p className="p-tag-text-right" >Long: {" "}
                                        {apiData.lastLongitude}
                                        </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={EndZipCodeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zipcode: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.endZipCode}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={EndLocationIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Location: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.endLocationName}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={EndAddressIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Address:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.endLocationName}, {apiData.endCity}, {apiData.endState}
                                  </p>
                                </div>

                               
                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={DataCountStartIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Start:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.startDataCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DataCountEndIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >End: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.endDataCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DistanceIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.distanceDataCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DataCountErrorIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Error:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.errorDataCount}
                                  </p>
                                </div>

                               

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AlertIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" > Alert:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.alertDataCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={StressStrainIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Stress Strain: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.stressStrainDataCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={ManualIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Manual: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.manualDataCount}
                                  </p>
                                </div>                          

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AlertLowIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Low:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.lowAlertCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AlertMediumIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Medium: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.mediumAlertCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={AlertHighIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >High: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.highAlertCount}
                                  </p>
                                </div>                          

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={NegativeIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Negative:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.negativeCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={ZeroIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Zero: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.zeroCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Sloat1Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 1: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slot1count}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot2Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 2:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slot2count}
                                  </p>
                                </div>

                               

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={Slot3Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Slot 3:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.slot3count}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot4Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 4: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slot4count}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Slot5Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 5: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slot5count}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot6Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 6:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slot6count}
                                  </p>
                                </div>

                               

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={Slot7Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Slot 7:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.slot7count} | {apiData.slot7DistanceInKm} Kms | {apiData.slot7DurationInMin} Mins
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot8Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 8: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slot8count} | {apiData.slot8DistanceInKm} Kms | {apiData.slot8DurationInMin} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Slot9Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 9: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slot9count} | {apiData.slot9DistanceInKm} Kms | {apiData.slot9DurationInMin} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={Slot10Icon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slot 10:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slot10count} | {apiData.slot10DistanceInKm} Kms | {apiData.slot10DurationInMin} Mins
                                  </p>
                                </div>

                               

                               
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AccLowIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Low:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.accelerationLowCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AccMediumIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Medium: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.accelerationMediumCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={AccHighIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >High: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.accelerationHighCount}
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={BrakLowIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Low:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.brakingLowCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={BrakMediumIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Medium: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.brakingMediumCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={BrakHighIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >High: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.brakingHighCount}
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={CornLowIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Low:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.corneringLowCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={CornMediumIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Medium: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.corneringMediumCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={CornHighIcon} alt="icons2" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >High: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.corneringHighCount}
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={OverSpeedCountIcon} alt="OverSpeedCountIcon" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Count:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.overSpeedCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={OverSpeedDistanceIcon} alt="OverSpeedDistanceIcon" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.overSpeedDistanceInKm} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={OverSpeedDurationIcon} alt="OverSpeedDurationIcon" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Duration: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.overSpeedDurationInMin} Mins
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MobileCallCount} alt="MobileCallCount" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Count:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.mobileCallCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MobileCallDistance} alt="MobileCallDistance" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.mobileCallDistanceInKm} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MobileCallDuration} alt="MobileCallDuration" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Duration: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.mobileCallDurationInMin} Mins
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={MobileScreenCount} alt="MobileScreenCount" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Count:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.mobileScreenCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={MobileScreenDistance} alt="MobileScreenDistance" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Distance: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.mobileScreenDistanceInKm} Kms
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={MobileScreenDuration} alt="MobileScreenDuration" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Duration: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.mobileScreenDurationInMin} Mins
                                  </p>
                                </div>

                                                            
                              </td>

                            

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={DrivingStateUnKnown} alt="DrivingStateUnKnown" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Unknown:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.unknownCount} | {apiData.unknownDistanceInKm} Kms | {apiData.unknownDurationInMin} Mins
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DrivingStateMprmal} alt="DrivingStateMprmal" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Normal: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.normalCount} | {apiData.normalDistanceInKm} Kms | {apiData.normalDurationInMin} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DrivingStateTired} alt="DrivingStateTired" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Tired: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.tiredCount} | {apiData.tiredDistanceInKm} Kms | {apiData.tiredDurationInMin} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DrivingStateAngry} alt="DrivingStateAngry" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Angry: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.angryCount} | {apiData.angryDistanceInKm} Kms | {apiData.angryDurationInMin} Mins
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={DrivingStyleUnKnown} alt="DrivingStyleUnKnown" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Unknown:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.unknownCountState} | {apiData.unknownDistanceInKmState} Kms | {apiData.unknownDurationInMinState} Mins
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DrivingStylenormal} alt="DrivingStylenormal" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Normal: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.normalCountState} | {apiData.normalDistanceInKmState} Kms | {apiData.normalDurationInMinState} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DrivingStyleSportive} alt="DrivingStyleSportive" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Sportive: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.sportiveCountState} | {apiData.sportiveDistanceInKmState} Kms | {apiData.sportiveDurationInMinState} Mins
                                  </p>
                                </div>

                               
                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={DrivingStyleEco} alt="DrivingStyleEco" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Eco:  </p>
                                    <p className="p-tag-text-left" >
                                    {apiData.ecoCountState} | {apiData.ecoDistanceInKmState} Kms | {apiData.ecoDurationInMinState} Mins
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={DrivingStyleChaotic} alt="DrivingStyleChaotic" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Chaotic: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.chaoticCountState} | {apiData.chaoticDistanceInKmState} Kms | {apiData.chaoticDurationInMinState} Mins
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={DrivingStyleAggressive} alt="DrivingStyleAggressive" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Aggressive: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.aggressiveCountState} | {apiData.aggressiveDistanceInKmState} Kms | {apiData.aggressiveDurationInMinState} Mins
                                  </p>
                                </div>

                               
                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={AccidentIcon} alt="AccidentIcon" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Accident:</p>
                                    <p className="p-tag-text-left" >
                                    {apiData.accidentCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={AnimalCrossingIcon} alt="AnimalCrossingIcon" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Animal Crossing: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.animalCrossingCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Caution} alt="Caution" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Caution: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.cautionCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Congestion} alt="Congestion" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Congestion: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.congestionCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Curve} alt="Curve" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Curve: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.curveCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Hill} alt="Hill" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Hill: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.hillCount}
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={HillDownwards} alt="HillDownwards" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Hill Downwards:</p>
                                    <p className="p-tag-text-left" >
                                    {apiData.hillDownwardsCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={HillUpwards} alt="HillUpwards" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Hill Upwards: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.hillUpwardsCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={IcyConditions} alt="IcyConditions" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Icy Conditions: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.icyConditionsCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Intersection} alt="Intersection" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Intersection: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.intersectionCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={LaneMerge} alt="LaneMerge" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Lane Merge: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.laneMergeCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={LowGearArea} alt="LowGearArea" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Low Gear Area: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.lowGearAreaCount}
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={NarrowRoad} alt="NarrowRoad" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Narrow Road:</p>
                                    <p className="p-tag-text-left" >
                                    {apiData.narrowRoadCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={NoOvertaking} alt="NoOvertaking" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >No Overtaking:</p>
                                  <p className="p-tag-text-left" >
                                  {apiData.noOvertakingCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={NoOvertakingTrucks} alt="NoOvertakingTrucks" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >No Overtaking Trucks: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.noOvertakingTrucksCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={PadestrianCrossing} alt="PadestrianCrossing" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Pedestrian Crossing: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.pedestrianCrossingCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Priority} alt="Priority" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Priority: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.priorityCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={PriorityToOncommingTraffic} alt="PriorityToOncommingTraffic" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Priority to Oncoming Traffic: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.priorityToOncomingTrafficCount}
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={RailwayCrossing} alt="RailwayCrossing" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Railway Crossing:</p>
                                    <p className="p-tag-text-left" >
                                    {apiData.railwayCrossingCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={RiskOfGrounding} alt="RiskOfGrounding" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Risk of Grounding: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.riskOfGroundingCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={RoundAbout} alt="RoundAbout" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Round About: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.roundAboutCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={SchoolZone} alt="SchoolZone" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >School Zone: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.schoolZoneCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={SlipperyRoads} alt="SlipperyRoads" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Slippery Roads: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.slipperyRoadsCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={StopSign} alt="StopSign" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Stop Sign: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.stopSignCount}
                                  </p>
                                </div>

                                                            
                              </td>

                              <td className="td-container" >
                                <div style={{display:"flex"}}>
                                  <div className="div-flex-reg-detail">
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                      <img src={TrafficLight} alt="TrafficLight" style={{ height: "25px", marginLeft: "-4px" }} />
                                    </div>
                                    <p className="p-tag-text-right" >Traffic Light</p>
                                    <p className="p-tag-text-left" >
                                    {apiData.trafficLightCount}
                                    </p>
                                  </div>
                                  
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }} >
                                    <img src={TramwayCrossing} alt="TramwayCrossing" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Tramway Crossing: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.tramwayCrossingCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Wind} alt="Wind" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Wind: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.windCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={WindingRoad} alt="WindingRoad" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Winding Road: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.windingRoadCount}
                                  </p>
                                </div>

                                <div className="div-flex-reg-act">
                                  <div style={{ display: "flex", justifyContent: "center", flexDirection: "column",  }} >
                                    <img src={Yield} alt="Yield" style={{ height: "25px", marginLeft: "-4px" }} />
                                  </div>
                                  <p className="p-tag-text-right" >Yield: </p>
                                  <p className="p-tag-text-left" >
                                  {apiData.yieldCount}
                                  </p>
                                </div>

                                                            
                              </td>

                            

                              

                              

                              

                              

                              

                              

                            </tr>
                        ))
                        ) 
                        
                        : dataLength === 0 ? ( 
                          <tr>
                            {" "}
                            <td colSpan="31">
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                  fontSize:"13px"
                                }}
                              >
                                {displayText.DATA_NOT_AVAILABLE}
                              </div>
                            </td>
                          </tr>
                        ) : dataLength === null ? (
                          <tr>
                            {" "}
                            <td colSpan="31">
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "100px",
                                  width: "100%",
                                }}
                              >
                                {displayText.FETCHING_DATA}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                        
                        }


                      </tbody>
                    </Table>          
                  </div>  
                </Col>
              </Row>



      
        <Row className="mt-3">
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div className="pagination">
                  <a>{pageNumber}</a>
                </div>
                <p style={{marginTop:"6px",marginLeft:"5px",marginRight:"5px"}} >of</p>             
              <div className="pagination ">                  
             <a>{totalPageCount}</a>             
           </div> 
           </div>
              ) : null}
              {dataLength > 0 ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
                ) : null}
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filters.durationFilter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}
