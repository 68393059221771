import React, { useState } from "react";
import { Typography, Button, Box, createTheme } from "@mui/material";
import "./ConfirmationPopup.css"

export default function ConformationPopupPdf({ Data, formSubmit, cancelSubmit }) {
    console.log(Data,"DataData")
    const [openModal, setOpenModal] = useState(false);

    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => setOpenModal(false);

    return(

        <Box className="Modal-body"  >

            {/* <div  style={{ height: "40px", backgroundColor: "#2C60D3", marginTop:"-45px" }}>
            <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>&nbsp;Confirm Action </span>
            </div> */}
            {/* <Typography mt={1} sx={{ fontWeight: 500, fontSize: '17px', color: '#000000' }}>Are you sure</Typography> */}
            <Typography sx={{ fontWeight: 500, fontSize: '12px', color: '#000000' }}>There is no record in the current page to generate PDF</Typography>
            <Typography sx={{ fontWeight: 500, fontSize: '12px', color: '#000000' }}>Are you sure want to generate PDF?</Typography>
            <br />
            <div className="btn-div">
                <Button variant="outlined" size="small" className='popup_Button' sx={{ width: "50px", borderRadius: "12px" }} onClick={formSubmit}>Yes</Button>
                <Button variant="outlined" size="small" className='popup_Button' sx={{ width: "50px", borderRadius: "12px", ml: 1 }} onClick={() => cancelSubmit()} >No</Button>
            </div>
        </Box>

    )
}
