import { Image, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
// import Logo from "../../../assets/images/pdf/pdfLogo.png";
import Logo from "../../../assets/images/logo/headerLogoImagePdfNew.png";
// import cnIcon from "../../../assets/images/pdf/pdfIconCN.png";
// import fnIcon from "../../../assets/images/pdf/pdfIconFN.png";
import cnIcon from "../../../assets/images/pdf/customerNamePDF.png";
import fnIcon from "../../../assets/images/pdf/firstNamePDF.png";
import dIcon from "../../../assets/images/pdf/pdfIconDate.png";
import mobileIcon from "../../../assets/images/pdf/mobileImage.png";
import UserIdIcon from "../../../assets/images/pdf/UserIdImagePdf.png";
import vehicleIcon from "../../../assets/images/pdf/vehicleType.png";

import IndPdfImage     from "../../../assets/images/pdf/IndPdfImage.png";
import numberPdfImage from "../../../assets/images/pdf/numberPdfImage.png";
import istPdfImage    from "../../../assets/images/pdf/istPdfImage.png";

import registarPdfImage     from "../../../assets/images/pdf/registarPdfImage.png";
import licensePdfImage from "../../../assets/images/pdf/licensePdfImage.png";
import userTypePdfImage    from "../../../assets/images/pdf/userTypePdfImage.png";

import {
  SC_COMPANY_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  DEVICE_TYPE,
  SC_USER_USERNAME,
  VEHICLE_TYPE,
  SC_USERNAME,
  SC_LOGIN_COMPANY_ADDRESS_LINE_1,
  SC_LOGIN_COMPANY_ADDRESS_LINE_2,
  SC_LOGIN_COMPANY_ADDRESS_CITY,
  SC_LOGIN_COMPANY_ADDRESS_STATE,
  SC_LOGIN_COMPANY_ADDRESS_COUNTRY,
  SC_LOGIN_COMPANY_ADDRESS_POSTAL_CODE,
  SC_TIMEZONE_ABBREVIATION,
  SC_TIME_ZONE_ISOCODE3,
  SC_TIME_ZONE_DIALINGCODE,
  SC_LOGIN_LICENSE_TYPE,
  SC_LOGIN_USERIDTYPE,
  SC_LOGIN_CREATEDAT
} from "../../../constant/StorageConstant";
import ValueFormat from "../../../util/ValueFormat";

export default function PdfHeaderV1() {
  const currentPath = window.location.pathname;
  const [userFirstName, setUserFirstName] = useState("");
  const [mobileType, setMobileType] = useState("");
  const [userName, setUserName] = useState("");
  const [vehicleType, setVehicleType] = useState("");

  useEffect(() => {
    if (currentPath.includes("/user-dashboard")) {
      setUserFirstName(localStorage.getItem(SC_USER_FIRST_NAME));
      setMobileType(localStorage.getItem(DEVICE_TYPE));
      setUserName(localStorage.getItem(SC_USER_USERNAME));
      setVehicleType(localStorage.getItem(VEHICLE_TYPE));
    } else {
      setUserFirstName(localStorage.getItem(SC_USER_FIRST_NAME));
      setUserName(localStorage.getItem(SC_USER_USERNAME))
      setMobileType(localStorage.getItem(DEVICE_TYPE));
      setVehicleType(localStorage.getItem(VEHICLE_TYPE));
    }
  }, [currentPath]);

  const companyName = localStorage.getItem(SC_COMPANY_NAME);
  const firstName = localStorage.getItem(SC_LOGIN_USER_FIRST_NAME);
  const userLabel = localStorage.getItem(SC_USERNAME);
  // console.log(userName,"userName")
   var abbreviation = localStorage.getItem(SC_TIMEZONE_ABBREVIATION) || "";
  var addressLine1 = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_LINE_1);
    var addressLine2 = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_LINE_2);
    var city         = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_CITY);
    var state        = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_STATE);
    var country      = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_COUNTRY);
    var postalCode   = localStorage.getItem(SC_LOGIN_COMPANY_ADDRESS_POSTAL_CODE);
    var isoCode3     = localStorage.getItem(SC_TIME_ZONE_ISOCODE3) || "";
    var dialingCode  = localStorage.getItem(SC_TIME_ZONE_DIALINGCODE) || "";

    var licenseType     = localStorage.getItem(SC_LOGIN_LICENSE_TYPE) || "";
    var userIDType     = localStorage.getItem(SC_LOGIN_USERIDTYPE) || "";
    var createdAt     = localStorage.getItem(SC_LOGIN_CREATEDAT) || "";

    licenseType = (licenseType != "undefined") ? licenseType : "";
    userIDType = (userIDType != "undefined") ? userIDType : "";
    createdAt = (createdAt != "undefined") ? createdAt : "";    
    
    
    isoCode3 = (isoCode3 != "undefined") ? isoCode3 : "";
    dialingCode = (dialingCode != "undefined") ? dialingCode : "";

    addressLine1 = (addressLine1 != "undefined") ? addressLine1 : "";
    addressLine2 = (addressLine2 != "undefined") ? addressLine2 : "";
    city = (city != "undefined") ? city : "";
    state = (state != "undefined") ? state : "";
    country = (country != "undefined") ? country : "";
    postalCode = (postalCode != "undefined") ? postalCode : "";
  // Font.register({ family: 'Segoe UI', src: source, fontStyle: 'normal', fontWeight: 'normal', fonts?: [] });

  // const header = [
  //   { title: "Customer Name : " + companyName },
  //   { title: "First Name : " + userFirstName },
  // ];
 const mobileTypeVal = (mobileType == "ANDROID") ? "Android" : (mobileType == "IOS") ? "Ios" : ""
  

  return (
    <View
      style={{
        // borderTop: "5px solid #ffcf24", backgroundColor: "#615AE4", flexDirection: "row", paddingTop: 5, paddingBottom: 5,
        borderTop: "5px solid #ffcf24",
        //  backgroundColor: "#f4f4f4",
        borderBottom: "1px solid lightgrey",
        flexDirection: "row",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 30,
        paddingRight: 30,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{width:"60%"}}>
      <View style={{ marginRight: "10px", zIndex: 9999 }}>
        <Image
          src={Logo}
          alt={"logo"}
          style={{
            height: "35",
            width: "140px",
            marginTop: "2px",
            marginBottom: "5px",
            zIndex: 9999,
          }}
        />
      </View>

      {/* <View >
      <Text
                style={{
                  fontSize: "8px",
                  fontWeight: 600,                 
                  color: "#615AE4",                 
                  fontFamily: "Helvetica",
                  textAlign:"center",
                  width: "140px",
                }}
              >Drive Safe, Arrive Safe</Text>
      </View> */}

      <View>

      <Text
                style={{
                  fontSize: "9px",
                  fontWeight: 600,
                  textAlign: "center",
                  // color: "#615AE4",
                  padding: 2,
                  width: "140px",
                  fontFamily: "Helvetica"
                }}
              >
                {"https://www.motivai.tech" }
              </Text>

      </View>

      
      </View>
      <View style={{width:"180%"}}>      
       
       <View style={{display:"flex",flexDirection:"column",justifyContent:"center",marginTop:"5px"}} className="px-2">

       <Text
          style={{
            fontSize: "14px",
            fontWeight: 600,
            textAlign: "center",
            color: "black",
           fontFamily: "Helvetica-Bold"
          }}
        >{companyName}</Text>

       {addressLine1 || addressLine2 && (<Text style={{fontSize:"10px",textAlign: "center",marginTop:"7px"}}>
        {addressLine1 && `${addressLine1},`} {addressLine2}
        </Text> )}

        <Text style={{fontSize:"10px",textAlign: "center",marginTop:"7px"}}>
        {city && `${city},`} {state && `${state},`} {country && `${country}.`} {postalCode && `Pin: ${postalCode}`}
        </Text>
        

        {/* <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            textAlign: "center",
            color: "black",
           fontFamily: "Helvetica",
           marginTop:"5px"
          }}
        >
          {header[3].title}
        </Text>

        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            textAlign: "center",
            color: "black",
           fontFamily: "Helvetica",
           marginTop:"5px"
          }}
        >
          {header[4].title}
        </Text> */}

       </View>
        
      

      </View>

      {/* {!currentPath.includes("/adminDashboard") && !currentPath.includes("/userRides") &&  (<View style={{width:"60%",flexDirection: "row",justifyContent:"flex-start"}}>

     <View
        style={{
          display:"flex",
          flexDirection: "row",
          justifyContent:"flex-end",       
          // gap: 15,
          // width:"140px"
          
        }}
      >
        <View
        style={{display:"flex",flexDirection:"column"}}
        >
          


           <View style={{display:"flex",flexDirection:"row",marginTop:"2px"}}>
            <View style={{display:"flex",justifyContent:'center',flexDirection:"column"}}>
            <Image src={fnIcon} style={{ height: "16px", width: "18px" }} />
            </View>
            <View style={{display:"flex",justifyContent:'center',flexDirection:"column"}}>
            <Text
          style={{
            fontSize: 11,
            fontWeight: 600,            
            color: "black",
            fontFamily: "Helvetica",
            marginLeft:"5px"
            
            
            
          }}
        >
          {firstName}
        </Text>
            </View>
          
          </View>

         

          <View style={{display:"flex",flexDirection:"row",marginTop:"15px",width:"100%",}}>
          <View style={{display:"flex",flexDirection:"row",justifyContent:"flex-start",width:"150%"}}>
            <View>
            <Image src={UserIdIcon} style={{ height: "17px", width: "16px",marginLeft:"3px"}} />
            </View>
            <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
            <Text
          style={{
            fontSize: 11,
            fontWeight: 600,            
            color: "black",
            fontFamily: "Helvetica",
             marginLeft:"5px"
            
          }}
        >
        User ID: {userName}
        </Text>
            </View>

            </View>

            <View style={{display:"flex",flexDirection:"row",justifyContent:"center",width:"75%"}}>
            <View>
            <Image src={mobileIcon} style={{ height: "16px", width: "10px",marginLeft:"3px" }} />
            </View>
            <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
            <Text
          style={{
            fontSize: 11,
            fontWeight: 600,            
            color: "black",
            fontFamily: "Helvetica",
             marginLeft:"5px"
            
          }}
        >
         {mobileTypeVal &&`${mobileTypeVal}`}
        </Text>
            </View>

            </View>

            <View style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",width:"75%"}}>
            <View>
            <Image src={vehicleIcon} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
            </View>
            <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
            <Text
          style={{
            fontSize: 11,
            fontWeight: 600,            
            color: "black",
            fontFamily: "Helvetica",
             marginLeft:"5px"
            
          }}
        >
        { vehicleType && `${
            (vehicleType == "FOUR_WHEELER")
                ? "4W"
                : (vehicleType == "TWO_WHEELER")
                ? "2W"
                : ""}`}
        </Text>
            </View>

            </View>
          
          </View>
          
         

        </View>
       
      </View>     

      </View>)} */}

      


      {/* {(currentPath.includes("/adminDashboard") || currentPath.includes("/userRides")) && ( */}
        
        <View style={{width:"60%",flexDirection: "row",justifyContent:"flex-start",gap:20}}>

        <View style={{display:"flex",flexDirection:"column"}}>

          <View style={{display:"flex",flexDirection:"row"}}>
          <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
             <Image src={IndPdfImage} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
             </View>

             <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
             <Text
          style={{
            fontSize: 11,
            fontWeight: 600,            
            color: "black",
            fontFamily: "Helvetica",
             marginLeft:"5px"
            
          }}
        >{isoCode3}</Text>
        </View>
          </View>

          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
          <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
             <Image src={numberPdfImage} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
             </View>
             <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
             <Text
          style={{
            fontSize: 11,
            fontWeight: 600,            
            color: "black",
            fontFamily: "Helvetica",
             marginLeft:"5px"
            
          }}
        >{dialingCode}</Text>
        </View>
          </View>

          <View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
          <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
             <Image src={istPdfImage} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
             </View>
             <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
             <Text
          style={{
            fontSize: 11,
            fontWeight: 600,            
            color: "black",
            fontFamily: "Helvetica",
             marginLeft:"5px"
            
          }}
        >{abbreviation}</Text>
        </View>
          </View>

        </View>

 {(currentPath.includes("/adminDashboard") || currentPath.includes("/userRides")) ? (<View style={{display:"flex",flexDirection:"column"}}>

<View style={{display:"flex",flexDirection:"row"}}>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Image src={registarPdfImage} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
   </View>

   <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>Registration Date:</Text>
</View>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>{createdAt ? createdAt : "-"}</Text>
</View>
</View>

<View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Image src={licensePdfImage} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
   </View>
   <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>License Type:</Text>
</View>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>{licenseType ? ValueFormat.capitalizeFirstLetterAndFormat(licenseType) : "-"}</Text>
</View>
</View>

<View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Image src={userTypePdfImage} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
   </View>
   <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>User ID Type:</Text>

</View>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>{userIDType ? ValueFormat.capitalizeFirstLetterAndFormat(userIDType) : "-"}</Text>
</View>
</View>

</View> ) :

(<View style={{display:"flex",flexDirection:"column"}}>

<View style={{display:"flex",flexDirection:"row"}}>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Image src={UserIdIcon} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
   </View>

   <View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>User ID:</Text>
</View>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>{userName}</Text>
</View>
</View>

<View style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Image src={fnIcon} style={{ height: "16px", width: "16px" }} />
   </View>
   
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>{firstName}</Text>
</View>
</View>

<View style={{display:"flex",flexDirection:"row",marginTop:"5px",gap:10}}>


<View style={{display:"flex",flexDirection:"column"}}>
<View style={{display:"flex",flexDirection:"row"}}>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Image src={mobileIcon} style={{ height: "16px", width: "10px",marginLeft:"3px" }} />
   </View>
  
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>{mobileTypeVal &&`${mobileTypeVal}`}</Text>
</View>
</View>
</View>

<View style={{display:"flex",flexDirection:"column"}}>
<View style={{display:"flex",flexDirection:"row"}}>
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Image src={vehicleIcon} style={{ height: "16px", width: "16px",marginLeft:"3px" }} />
   </View>
  
<View style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
   <Text
style={{
  fontSize: 11,
  fontWeight: 600,            
  color: "black",
  fontFamily: "Helvetica",
   marginLeft:"5px"
  
}}
>{ vehicleType && `${
            (vehicleType == "FOUR_WHEELER")
                ? "4W"
                : (vehicleType == "TWO_WHEELER")
                ? "2W"
                : ""}`}</Text>
</View>
</View>
</View>

</View>

</View>)}

      </View>
    
    {/* // )} */}




      {/* <View style={{ width: "30%", marginRight: "10px", zIndex: 9999 }}>
        <Image
          src={Logo}
          alt={"logo"}
          style={{
            height: "30px",
            width: "140px",
            marginTop: "2px",
            marginBottom: "5px",
            zIndex: 9999,
          }}
        />
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
          width: "30%",
        }}
      >
        <Image
          src={cnIcon}
          style={{ height: "22px", width: "21px" }}
          alt={"fg"}
        />
        <Text
          style={{
            fontSize: 11,
            fontWeight: 600,
            textAlign: "left",
            color: "black",
            fontFamily: "Helvetica",
          }}
        >
          {header[0].title}
        </Text>
      </View>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
          width: "30%",
        }}
      >
        <Image src={fnIcon} style={{ height: "18px", width: "18px" }} />
        <Text
          style={{
            fontSize: 11,
            fontWeight: 600,
            textAlign: "left",
            color: "black",
            fontFamily: "Helvetica",
          }}
        >
          {header[1].title}
        </Text>
      </View> */}
    </View>
  );
}
