import { apiConfig } from "../config/Config.js";
import { apiEndPoint } from "../constant/ApiConstant.js";
import { ApiService } from "../service/ApiService.js";
import UtilDateTime from "../util/UtilDateTime.js";
import { trimValue } from "../util/UtilFieldValidation.js";
import {
  SC_COMPANY_ID,
  SC_USER_ID,
  SC_DIVISION_ID,
  SC_GROUP_ID,
  SC_LOGIN_USER_ID,
} from "../constant/StorageConstant.js";
import UserSessionController from "./UserSession/UserSessionController.js";

let SuperAdminCrashCtrlr = {



 // ---------------------------------- Vehicle Crash List api -------------------------------------------------- //

async getSuperAdminV1VehicleCrashList(
    indexStart,
    filter,
    deviceFilter,
    startDateTime,
    endDateTime,
    accidentTypeFilter,   
    pageSize
  ) {
  
    var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
    var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
    var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
    var queryFields    = {
                            durationType  : filter,
                            accidentType  : accidentTypeFilter,
                            deviceType    : deviceFilter,
                          };
  
    if (companyId.length > 0 && divisionId.length > 0) {
  
      var reqPayload = {
        companyId       : companyId,
        groupId         : groupId,
        divisionId      : divisionId,       
        offset          : indexStart,
        limit           : pageSize,     
        queryType       : "QV1_VEHICLE_CRASH_LIST",
        startDateTime   : startDateTime,
        endDateTime     : endDateTime,
        queryFields     : JSON.stringify(queryFields),
      };
  
      return await ApiService.encryptCallGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.QUERY,
        1,
        reqPayload
      );
  
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  
  },
  
  // --------------------------------------------------------------------------------------------- //

  // ---------------------------------- Vehicle Crash List api New Updated -------------------------------------------------- //

async getSuperAdminV2VehicleCrashList(
  indexStart,
  filter,
  deviceFilter,
  startDateTime,
  endDateTime,
  accidentTypeFilter,   
  pageSize
) {

  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  var queryFields    = {
                          durationType  : filter,
                          accidentType  : accidentTypeFilter,
                          deviceType    : deviceFilter,
                        };

  if (companyId.length > 0 && divisionId.length > 0) {

    var reqPayload = {
      companyId       : companyId,
      groupId         : groupId,
      divisionId      : divisionId,       
      offset          : indexStart,
      limit           : pageSize,     
      queryType       : "QV2_VEHICLE_CRASH_LIST",
      startDateTime   : startDateTime,
      endDateTime     : endDateTime,
      queryFields     : JSON.stringify(queryFields),
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.QUERY,
      1,
      reqPayload
    );

  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }

},

// --------------------------------------------------------------------------------------------- //
  // ---------------------------------- Vehicle Single Crash List api New Updated -------------------------------------------------- //

async getSuperAdminV2VehicleCrashSingle(rideId, userId, divisionId, ) {

  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var divisionId      = UserSessionController.getLocalSessionValue(SC_DIVISION_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  
  var queryFields    = {
    durationType  : "",
    accidentType  : "",
    deviceType    : "",
  };

  if (companyId && divisionId) {

    var reqPayload = {
      deviceId        : rideId,
      userId          : userId,
      companyId       : companyId,
      groupId         : groupId,
      divisionId      : divisionId,     
      sortBy          : "createdAt",
      sortOrder       : "ASC",     
      queryType       : "QV2_VEHICLE_CRASH_LIST",     
      queryFields     : JSON.stringify(queryFields),
    };

    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.QUERY,
      1,
      reqPayload
    );

  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }

},

// --------------------------------------------------------------------------------------------- //




// ------------------------------------- Crash Details api New Updated-------------------------------------------------------- //

async getCrash1KmErrorData(divisionId,rideId,userId) {
  rideId = trimValue(rideId);
  var queryFields = {showLastData : "YES", travelType: "DRIVER"};
  var companyId       = UserSessionController.getLocalSessionValue(SC_COMPANY_ID);
  var groupId         = UserSessionController.getLocalSessionValue(SC_GROUP_ID);
  
  if (rideId) {
    var reqParams = {
      companyId: companyId,
      groupId:groupId,
      divisionId: divisionId,
      userId: userId,
      deviceId: rideId,
      category:"START_DATA,DISTANCE_DATA,ERROR_DATA,END_DATA",     
      sortBy: "createdAt",
      sortOrder: "ASC",
      offset: "0",
      limit: "1000",
      subCategory:"",
      status:"",
      queryType: "QV1_CRASH_VIEW_RIDE_DATA_LIST",
      queryFields: JSON.stringify(queryFields)
    };
    return await ApiService.encryptCallGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.QUERY,
      1,
      reqParams
    );
  } else {
    return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
  }
},


// --------------------------------------------------------------------------------------------- //


};

export default SuperAdminCrashCtrlr;
