 export const SC_USERNAME = 'username';
 export const SC_USER_EMAIL = 'userEmail';
 export const SC_NAME = 'name';
 export const SC_USER_FIRST_NAME = 'userFirstName';
 export const SC_USER_LAST_NAME = 'userLastName';
 export const SC_EMAIL = 'email';
 export const SC_USER_ID = 'userId';
 export const SC_FCM_NOTIFICATION_TOKEN = 'fcmToken';
 export const SC_PREVIOUS_FCM_NOTIFICATION_TOKEN = 'previousFcmToken';
 export const SC_FCM_NOTIFICATION_COUNT = 'fcmCountNotification';
 export const SC_FCM_VEHICLECRASHES_COUNT = 'fcmCountVehicleCrashes';
 export const SC_LOGIN_USER_FIRST_NAME = 'loginUserFirstName';
 export const SC_LOGIN_USER_ID = 'loginUserId';
 export const SC_LOGIN_GROUP_ID = 'loginGroupId';
 export const SC_USER_USERNAME = 'userUsername';
 export const SC_PROFILE_IMAGE = "profilePicturePath";
 export const SC_PROFILE_IMAGE_USER = "profilePicturePathUser";

 export const SC_COMPANY_ID = 'companyId';
 export const SC_DIVISION_ID = 'divisionId';
 export const SC_MODULE_ID = 'moduleId';
 export const SC_GROUP_ID = 'groupId';
 export const SC_COMPANY_NAME = 'companyName';
 export const SC_DIVISION_NAME = "divisionName";
 export const SC_REGISTRATION_DATE = "userRegistrationDate";
 export const SC_LOGIN_DIVISION_ID = 'loginDivisionId';
 export const SC_LOGIN_MODULE_ID = 'loginModuleId';
 export const SC_USER_SESSION_ID = 'userSessionId';
 export const SC_EXPIRATION_TIME = 'expirationTime';
 export const SC_USER_LOGIN_TYPE = 'userLoginType';
 export const SC_USER_LAST_RIDE_ID = 'userLastRideId';
 export const SC_LANGUAGES = 'languages';
 export const SC_DASHBOARD_TYPE = 'dashboardType';
 export const SC_PASSWORD = 'password';
 export const SC_LOCK_STATUS = 'lockStatus';
 export const SC_TOTAL_RIDE_COUNT = 'totalRideCount';
 export const SC_PROFILE_OPEN = 'profileStatus';
 export const SC_SECRET_KEY = 'secretKey';
 export const SC_TIME_ZONE_ID = 'timeZoneId';
 export const SC_LANGUAGE_ID = 'languageId';
 export const SC_COUNTRY_ID = "countryId";

 export const SC_TIME_ZONE_ISOCODE3 = 'timeZoneIsoCode3';
 export const SC_TIME_ZONE_DIALINGCODE = 'timeZoneDialingCode';

 export const SC_TIMEZONE_ABBREVIATION = "TZabbreviation";
 export const SC_TIMEZONE_DISPLAY_NAME = "TZdisplayName";
 export const SC_TIMEZONE_GMT_OFFSET = "TZgmtOffset";
 export const SC_TIMEZONE_ZONE_NAME = "TZzoneName";
 export const SC_TIMEZONE_DST_OFFSET = "TZdstOffset";

 export const RIDEDETAILS = "rideDetails";

 export const DEVICE_TYPE = "deviceType";
 export const VEHICLE_TYPE = "vehicleType";
 export const SC_SELECTED_USER_VALUE = "selectedUserValue"

 export const SC_LOGIN_COMPANY_NAME_NEW = "loginCompanyNameNew"
 export const SC_LOGIN_COMPANY_ADDRESS_NEW = "loginCompanyAddressNew"

 export const SC_LOGIN_COMPANY_ADDRESS_LINE_1 = "addressLine1"
 export const SC_LOGIN_COMPANY_ADDRESS_LINE_2 = "addressLine2"
 export const SC_LOGIN_COMPANY_ADDRESS_CITY = "addressLineCity"
 export const SC_LOGIN_COMPANY_ADDRESS_STATE = "addressLineState"
 export const SC_LOGIN_COMPANY_ADDRESS_COUNTRY = "addressLineCountry"
 export const SC_LOGIN_COMPANY_ADDRESS_POSTAL_CODE = "addressLinePostalCode"

 export const SC_PROFILE_ADMIN_TO_USER = "profilePicturePathAdminToUser"

 export const SC_LOGIN_LICENSE_TYPE = "licenseType"
 export const SC_LOGIN_USERIDTYPE = "userIDType"
 export const SC_LOGIN_CREATEDAT = "createdAt"
